import React, { useState, useEffect } from 'react';
import { Popover, Space } from 'antd';

const Homepage = () => {
    return (
        <div style={{ height: '100vh', backgroundColor: 'black' }}>
            {/* <video className="video-box" autoPlay loop muted>
                <source src={mp4} type="video/mp4" />
            </video> */}
            <div className="bg homepage-bg" />
            <div className="agreement" />
            <div className="time" />
            <div className="title" />
            <div className="main-logo" />
            <div className="download-panel">
                <Space>
                    <a
                        href="https://package.jinzhangshu.com/SecondTest/Launcher/IntoTheVoidSetup_tap_1.1.1.30.exe"
                        target="text"
                    >
                        <div className="download-pc" />
                    </a>
                    <a href="https://www.taptap.cn/app/273409" target="text">
                        <div className="download-tap" />
                    </a>
                </Space>
            </div>
        </div>
    );
};
export default Homepage;
