import React, { useState, useEffect } from 'react';
import '@styles/index.css';
import '@styles/character.css';
import { Col, Row, Space } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';

const Character = () => {
    const [selectedChar, setSelectedChar] = useState(0);
    const [avatarSwiper, setAvatarSwiper] = useState(null);

    return (
        <div className="bg character-bg">
            {/* <div className="avatar-range">
                <Swiper
                    className="avatar-swiper"
                    slidesPerView={4}
                    speed={500}
                    mousewheel={false}
                    keyboard={{ enabled: false }}
                    onSlideChange={(e) => setSelectedChar(e.activeIndex)}
                    onSwiper={(swiper) => {
                        setAvatarSwiper(swiper);
                    }}
                >
                    {Array.from({ length: 9 }).map((_, idx) => (
                        <SwiperSlide key={'avatar' + idx}>
                            <div className={`avatar avatar-${idx + 1}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div> */}
        </div>
    );
};
export default Character;
