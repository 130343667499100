import React from 'react';

import Footer from '@components/footer';

const Last = () => {
    return (
        <div style={{ height: '100vh', background: 'black' }}>
            <div className="bg last_bg"></div>
            <Footer />
        </div>
    );
};
export default Last;
