import React from 'react';
import '@styles/component.css';
import { useHistory } from 'react-router-dom';
import { Col, Row, Space } from 'antd';

const Footer = () => {
    const history = useHistory();
    const tags = [
        { id: 'user', label: '用户协议' },
        { id: 'privacy', label: '隐私政策' },
        { id: 'children', label: '儿童隐私政策' },
    ];
    return (
        <div className="footer">
            <Row>
                <Col lg={{ span: 3, offset: 6 }} xs={24}>
                    <div style={{ textAlign: 'center' }}>
                        <div className="footer-logo" />
                    </div>
                </Col>
                <Col lg={9} xs={24}>
                    <Space align="start" style={{ paddingBottom: '20px' }}>
                        {tags.map((i, j) => (
                            <Space
                                style={{ cursor: 'pointer' }}
                                key={'footer-label' + i.id}
                                onClick={() => history.push('/policy/' + i.id)}
                            >
                                {j !== 0 ? <div>|</div> : null}
                                <div>{i.label}</div>
                            </Space>
                        ))}
                    </Space>
                    <div className="label">
                        <div className="advises">
                            <div className="advise">
                                抵制不良游戏，拒绝盗版游戏。
                            </div>
                            <div className="advise">
                                注意自我保护，谨防受骗上当。
                            </div>
                            <div className="advise">
                                适度游戏益脑，沉迷游戏伤身。
                            </div>
                            <div className="advise">
                                合理安排时间，享受健康生活。
                            </div>
                        </div>
                        <a href="https://beian.miit.gov.cn/" target="_blank">
                            琼ICP备2023002337号-1
                        </a>
                        <div>
                        <Space>
                        <p>版权所有 | 杭州紧张树科技有限公司</p>
                        <p style={{marginLeft: "20px"}}>客服电话：15167142084</p>
                        </Space>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default Footer;
