import axios from 'axios';
import * as XLSX from 'xlsx';

const headers = {
    'content-type': 'application/json',
};

const serverAddress = 'https://pay.jinzhangshu.com';
// const serverAddress = 'http://127.0.0.1:8083';

const getURL = () => {
    return serverAddress;
};

export const Get = (path, params, deal) => {
    axios
        .get(getURL() + path, {
            params: params,
            headers: headers,
        })
        .then((res) => deal(res))
        .catch((err) => deal(err));
};

export const Post = (path, params, deal, cover) => {
    axios
        .post(getURL() + path, params, {
            headers: headers,
        })
        .then((res) => deal(res))
        .catch((err) => cover(err));
};

export const AuthGet = (path, params, deal) => {
    var Authorization = localStorage.getItem('bearer_token');
    axios
        .get(getURL() + path, {
            params: params,
            headers: {
                ...headers,
                Authorization,
            },
        })
        .then((res) => deal(res))
        .catch((err) => deal(err));
};

export const AuthPost = (path, params, deal) => {
    var Authorization = localStorage.getItem('bearer_token');
    axios
        .post(getURL() + path, params, {
            headers: {
                ...headers,
                Authorization,
            },
        })
        .then((res) => deal(res))
        .catch((err) => deal(err));
};
