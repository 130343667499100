import React from 'react';
import { Row, Col } from 'antd';

const User = () => {
    return (
        <div>
            <Row>
                <Col lg={{ span: 18, offset: 3 }}>
                    <div style={{ paddingTop: '10vh' }}>
                        <div className="policy-title">
                            紧张树科技使用许可及服务协议
                        </div>
                        <div className="content">
                            本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切冲突法的适用。如双方就本协议的内容或其执行发生任何争议（包括但不限于合同或者其他财产性权益纠纷），双方应友好协商解决；协商不成时，双方同意交由杭州紧张树科技有限公司所在地人民法院管辖并处理。
                        </div>
                        <div className="content">
                            《紧张树科技使用许可及服务协议》（以下简称“本协议”）由您与紧张树服务提供方共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制紧张树责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款，以及开通或使用某项服务的单独协议。前述限制、免责及争议解决方式和管辖条款可能以标红/黑体/加粗或其他合理方式提示您注意。
                        </div>
                        <div className="content">
                            除非您已阅读并接受本协议所有条款，否则您无权使用紧张树服务。您使用紧张树服务即视为您已阅读并同意签署本协议。
                        </div>
                        <div className="content">
                            如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款，您在使用紧张树服务之前，应取得监护人对您依据本协议使用紧张树服务行为的同意。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第一条 协议签署方</strong>
                        </div>
                        <div className="content">
                            本协议在如下双方之间签署：
                        </div>
                        <div className="content">
                            1、杭州紧张树科技有限公司。即紧张树游戏服务的提供方。紧张树公司可能通过自有的网站与平台、合作方的网站与平台向您提供紧张树服务。
                        </div>
                        <div className="content">
                            2、您，又称“玩家”或“用户”。即通过紧张树公司提供的合法途径，获得紧张树公司非商业使用性质之授权，从而安装、使用紧张树软件或接受紧张树服务的自然人。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二条 定义和说明</strong>
                        </div>
                        <div className="content">
                            如无相反说明，以下词语具有如下含义：
                        </div>
                        <div className="content">
                            1、紧张树软件：指紧张树公司提供的游戏软件（包括任何可在移动智能设备及/或电脑终端上，以客户端或网页等形式进行操作的适配版本），以及与该等游戏软件相关的更新、升级、补丁、资料片等。并可能包括相关的网络络服务器、网站（包括但不限于该游戏之官方网站或官方论坛)、电子媒体、印刷材料或电子文档等。
                        </div>
                        <div className="content">
                            2、紧张树服务：指紧张树公司向您提供的、与前述紧张树软件相关的各项在线运营服务，并包括紧张树软件本身。
                        </div>
                        <div className="content">
                            3、增值服务代用币：指紧张树公司为满足玩家在紧张树服务中的正常交易交流需求，而设计的一种虚拟道具，通过人民币兑换等途径获得，可用于换取游戏中指定的各类增值服务，仅限于在特定游戏中使用和流通。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第三条 知识产权声明</strong>
                        </div>
                        <div className="content">
                            1、紧张树公司依法享有紧张树软件的一切合法权益（包括但不限于其中涉及的计算机软件著作权、美术作品著作权和专利权等知识产权，运营该等紧张树软件的权利等），或已从合法权利人处取得合法授权，从而有权为用户提供紧张树软件及紧张树服务。
                        </div>
                        <div className="content">
                            2、紧张树服务所体现的“紧张树”的文字、LOGO（图形），以及在具体服务中使用的用以标识服务名称与来源的文字、LOGO（图形）等（包括但不限于紧张树服务的名称、LOGO、ICON，紧张树软件开发者的名称、LOGO、ICON等），属于紧张树公司及/或其关联公司、合作公司在中国及/或其他国家/地区的商标或者注册商标。
                        </div>
                        <div className="content">
                            3、紧张树服务可能涉及第三方知识产权，如该等第三方对您在紧张树服务中使用该等知识产权有要求的，紧张树公司将以适当方式向您告知该等要求，您应当遵守该等要求。
                        </div>
                        <div className="content">
                            4、您在使用紧张树服务中产生的游戏数据的所有权和全部相关的所有权及知识产权归紧张树公司所有，在法律所允许的最大限度内，紧张树公司有权保存、处置该游戏数据。
                        </div>
                        <div className="content">
                            5、本协议未明确授予您的权利均由紧张树公司保留。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第四条 紧张树软件使用许可与限制</strong>
                        </div>
                        <div className="content">
                            1、在用户同意接受本协议全部条款的前提下，紧张树公司同意授予用户非商业使用性质的、可撤销的、可变更的、非专有的、不可转让和不可转授权的使用本紧张树软件及紧张树服务的权利。用户可在授权范围内将本紧张树软件安装在供个人使用的移动智能设备或电脑终端上，并以紧张树公司指定的方式运行本紧张树软件的一份副本并享受紧张树公司提供的服务。
                        </div>
                        <div className="content">
                            紧张树公司基于本协议授予用户的许可是针对个人使用的许可。如用户有需要在个人使用的范围以外使用紧张树软件及紧张树服务或者将紧张树软件与紧张树服务用于任何商业用途，则用户应与紧张树公司联系并获得紧张树公司另行授权。任何未经紧张树公司许可的安装、使用、访问、显示、运行、商业使用等行为均属对紧张树公司的侵权。
                        </div>
                        <div className="content">
                            2、除非本协议另有约定，否则，未经紧张树公司事先书面同意，用户不得实施下列行为（无论是营利的还是非营利的）：
                        </div>
                        <div className="content">
                            (1)复制、翻录、反编译（de-compile）、反汇编（disassemble）、传播和陈列紧张树软件的程序、使用手册和其它图文音像资料的全部或部分内容，或对紧张树软件的任何功能或程序进行反向工程（reverseengineering）。
                        </div>
                        <div className="content">
                            (2)公开展示和以各种形式、在各种平台（包括但不限于网络直播平台、网站、APP等）播放紧张树软件/紧张树服务的全部或部分内容。
                        </div>
                        <div className="content">
                            (3)出租、销售紧张树软件或者利用紧张树软件从事任何营利行为。
                        </div>
                        <div className="content">
                            (4)修改或遮盖紧张树软件程序、图像、动画、包装和手册等内容上的服务名称、公司标志、商标（或）版权信息、其他知识产权声明等内容。
                        </div>
                        <div className="content">
                            (5)其他以不符合本协议所规定的方式使用紧张树公司的名称、商标或其它知识产权的行为；
                        </div>
                        <div className="content">
                            (6)其它违反商标法、著作权法、计算机软件保护条例和相关法律法规、国际条约的行为。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第五条 资费政策</strong>
                        </div>
                        <div className="content">
                            1、紧张树公司有权决定紧张树软件及紧张树服务的收费方式和资费标准，紧张树公司有权就紧张树软件和紧张树服务在不同阶段确定不同的收费方式和资费标准，且紧张树公司有权根据需要对资费政策随时进行调整。
                        </div>
                        <div className="content">
                            具体收费方式、资费标准，包括但不限于紧张树软件的收费标准、游戏中虚拟物品的使用权以及增值服务（如有）的具体收费标准等，均由紧张树公司在APP
                            STORE或其他APP发布渠道、游戏内道具商城、道具购买界面、游戏官网等游戏模块上，以网络游戏行业通用的形式颁布。
                        </div>
                        <div className="content">
                            用户有义务在选择前仔细阅读，一旦用户选择下载收费的紧张树软件、购买相关虚拟物品使用权或者接受相关服务，则表明用户已经充分理解并接受相应的收费方式和资费标准。
                        </div>
                        <div className="content">
                            2、紧张树公司有权根据需要，在游戏中设立、添加、修改增值服务并发行增值服务代用币。
                        </div>
                        <div className="content">
                            增值服务代用币通过使用人民币兑换获得，人民币一经兑换成增值服务代用币，则视为人民币已经使用，增值服务代用币不可兑换成人民币；增值服务代用币用于购买相关虚拟道具使用权或者接受相关增值服务。
                        </div>
                        <div className="content">
                            在以用户购买虚拟物品的使用权或者以接受其他增值服务作为收费项目的情况下，如超出该虚拟物品的有效使用期限或增值服务的有效服务期限（不管用户是否已经实际使用或者享受服务），或者超出有效使用或服务次数，紧张树公司即有权采取措施取消用户对上述虚拟物品的使用权或者停止提供增值服务。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第六条 游戏虚拟物品</strong>
                        </div>
                        <div className="content">
                            1、游戏虚拟物品（或简称“虚拟物品”）包括但不限于游戏角色、资源、道具（包括但不限于游戏中的装备等）等，其所有权归紧张树公司，用户只能在合乎法律规定的情况下，根据游戏规则进行使用。
                        </div>
                        <div className="content">
                            2、游戏中各类虚拟物品，如对使用期限无特殊标识的，均默认用户可在获得使用权后持续使用，直至相应紧张树服务终止；如对使用期限有特殊标识的，则其使用期以特殊标识的期限为准，超过使用期的，紧张树公司有权不另行通知用户而随时收回其使用权（该使用期限不因任何原因而中断、中止）。
                        </div>
                        <div className="content">
                            3、从第三方获得游戏内虚拟物品可能存在各种风险。您不得从第三方获得上述游戏虚拟物品，您如果坚持从第三方获得上述游戏虚拟物品，则您应当自行承担相应的风险，紧张树公司有权直接将该等游戏虚拟物品索回、永久封禁或做删除等处理，紧张树公司不对第三方交易的行为负责，并且不受理因任何第三方交易发生纠纷而带来的申诉。
                        </div>
                        <div className="content">
                            4、紧张树公司因用户从事违反本协议或法规行为而采取的措施，可能会导致您当前游戏账号项下的有使用期限的虚拟物品、虚拟物品兑换码等因使用期限在紧张树公司采取上述措施期间届满而无法继续使用，由此给您造成的损失由您自行承担。即紧张树公司采取上述措施所持续的时间是计算在有使用期限的虚拟物品、虚拟物品兑换码的使用期限当中的；该等措施执行终止后，虚拟物品、虚拟物品兑换码的使用期限并不会因此续延。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第七条 用户帐号注册、使用与保管</strong>
                        </div>
                        <div className="content">
                            1、为共同向您提供产品服务或改进产品服务的质量或出于对产品服务安全性的考量合理需要，我们可能按照相关法律法规及监管政策的要求或经过您的授权从关联公司、合作方及其他受信任的第三方接收您的个人信息及其他信息。帐号注册用户承诺以其真实身份注册紧张树公司认可的其他合作方帐号，并以该等帐号（下亦称“用户帐号”）接受紧张树服务。用户同意，以用户提供的真实准确的个人资料作为认定用户与游戏帐号的关联性以及用户身份的唯一证据。您对您所创建的游戏账号只享有使用权，游戏账号所有权归紧张树公司所有。
                        </div>
                        <div className="content">2、用户帐号使用与保管</div>
                        <div className="content">
                            (1)根据必备条款的约定，紧张树公司有权审查您注册时所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户帐号的安全、有效；您有义务妥善保管用户帐号及密码，并正确、安全地使用其用户帐号及密码。任何一方未尽上述义务导致用户帐号密码遗失、用户帐号被盗等情形而给您或他人的民事权利造成损害的，应当承担由此产生的法律责任。
                        </div>
                        <div className="content">
                            (2)您对所持用户帐号产生的行为依法享有权利和承担责任。
                        </div>
                        <div className="content">
                            (3)一旦发现用户帐号或密码被他人非法使用或有使用异常的情况的，您应及时根据帐号服务提供方公布的处理方式进行处理，并有权及时通知紧张树服务提供方紧张树公司采取措施暂停该用户帐号的登录和使用。
                        </div>
                        <div className="content">
                            (4)紧张树公司根据用户的通知采取措施暂停用户帐号的登录和使用的，紧张树公司应当要求用户提供并核实与其注册身份信息相一致的个人有效身份信息或证明。
                        </div>
                        <div className="content">
                            (5)紧张树公司核实用户所提供的个人有效身份信息或证明与所注册的身份信息相一致的，应当及时采取措施暂停用户帐号的登录和使用。
                        </div>
                        <div className="content">
                            (6)紧张树公司违反前项⑸的约定，未及时采取措施暂停用户帐号的登录和使用，因此而给用户造成损失的，应当承担相应的法律责任。
                        </div>
                        <div className="content">
                            (7)用户没有提供其个人有效身份信息或证明，或者用户提供的个人有效身份信息或证明与所注册的身份信息不一致的或用户账号信息发生变更而未及时修改，导致无法证明您的有效身份，紧张树公司有权拒绝用户上述请求。
                        </div>
                        <div className="content">
                            (8)用户为了维护其合法权益，向紧张树公司提供与所注册的身份信息相一致的个人有效身份信息或证明时，紧张树公司应当依法为用户提供帐号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。
                        </div>
                        <div className="content">
                            (9)如用户连续365天没有登陆游戏，则自第365天当天的24时起，紧张树公司有权采取措施删除该用户帐号以及该用户帐号在游戏数据库中的任何记录（包括但不限于角色、等级、虚拟物品、增值服务代用币等数据信息），删除后的数据信息无法再恢复，上述处置可能导致您对该游戏账号下相关权益的丧失，对此紧张树公司不承担任何责任。
                        </div>
                        <div className="content">
                            (10)您有义务妥善保管您的游戏账号及相关密码，并正确、安全地使用您的游戏账号及密码。您依照本协议约定注册的游戏账号，仅用于您个人游戏数据的保存、备份的目的。游戏账号使用权仅属于初始申请注册人，禁止赠与、分配、转让、继受或售卖，您不得将游戏账号以任何方式提供给他人使用，包括但不限于不得以任何形式提供给他人进行包括但不限于游戏直播、视频录制、代打代练、刷装备、技能（术）展示等具备或可能具备商业性质的使用（无论是否以盈利为目的或是否盈利）。否则，紧张树公司有权视情况对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏账号全部或部分功能、删除游戏账号及游戏数据及其他相关信息、封号直至注销等处理措施，因此产生一切法律后果及损失均由您自行承担。如果您并非账号初始注册人，因您未妥善保管账号及密码，并正确、安全地使用账号及密码导致账号密码遗失、账号被盗等情形而给您和他人的民事权利造成损害的，您需要承担由此产生的法律责任。
                        </div>
                        <div className="content">
                            (11)紧张树公司禁止用户私下有偿或无偿转让游戏账号，以免因游戏账号问题产生纠纷，紧张树公司不受理因任何用户私下交易发生纠纷而带来的申诉，您应当自行承担因违反此要求而遭致的任何损失，同时紧张树公司保留追究上述行为人法律责任的权利。
                        </div>
                        <div className="content">
                            3、紧张树公司会按照国家相关要求将您的实名注册信息运用于防沉迷系统之中，即紧张树公司可能会根据您的实名注册信息判断您是否年满18周岁，从而决定是否对您的游戏账号予以防沉迷限制。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第八条 用户隐私信息保护与收集</strong>
                        </div>
                        <div className="content">
                            1、紧张树公司要求用户提供与其个人身份有关的信息资料时，应当事先以明确而易见的方式向用户公开其隐私权保护政策和个人信息利用政策，并采取必要措施保护用户的个人信息资料的安全。
                        </div>
                        <div className="content">
                            2、在不透露单个用户隐私资料的前提下，紧张树公司有权对整个用户数据库进行技术分析并对已进行分析、整理后的用户数据库进行商业上的利用。尽管紧张树公司对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失。
                        </div>
                        <div className="content">
                            3、未经用户许可紧张树公司不得向任何第三方公开或共享用户注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：
                        </div>
                        <div className="content">
                            (1)事先得到用户或用户监护人的明确授权；
                        </div>
                        <div className="content">
                            (2)按照有关的法律法规要求；
                        </div>
                        <div className="content">
                            (3)按照司法机关或其它有权机关基于法定程序的要求；
                        </div>
                        <div className="content">
                            (4)按照相关政府主管部门的要求；
                        </div>
                        <div className="content">(5)为维护社会公众的利益；</div>
                        <div className="content">
                            (6)在紧急情况时为维护其它用户或第三人的利益的情形下；
                        </div>
                        <div className="content">
                            (7)为维护紧张树自身的合法权益而向用户提起诉讼或者仲裁时；
                        </div>
                        <div className="content">
                            (8)根据本条款相关规定或者紧张树认为必要的其他情况下。
                        </div>
                        <div className="content">
                            4、紧张树公司可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与紧张树公司同等的保护用户隐私的责任，则紧张树公司可将用户的注册资料等提供给该第三方并限定该第三方的使用范围。
                        </div>
                        <div className="content">
                            5、紧张树公司将一如既往地按照国家规定采取技术和管理措施保证网络信息安全，包括防范计算机病毒入侵和攻击破坏，备份重要数据库，保存用户注册信息等资料，依法保护用户的个人信息。
                        </div>
                        <div className="content">
                            6、在不透露单个用户隐私资料的前提下，用户同意紧张树公司及其关系企业或合作对象，可以在合理范围内收集、处理、分析、保存及使用该资料，以提供用户其它信息及服务或做成会计资料，或进行网络行为的调查或研究，或其它任何合法使用。
                        </div>
                        <div className="content">
                            为保障用户的隐私权、规范对用户个人信息的利用，紧张树公司制定了《用户隐私协议》，请用户详细阅读该协议的内容。
                        </div>
                        <div className="content">
                            7、除前述个人信息资料之外，您同意并授权紧张树公司基于行政/司法机关的要求或安全、用户体验优化等考虑而收集您的其他必要信息，但紧张树公司仅可为履行本协议之目的收集与利用该等信息。
                        </div>
                        <div className="content">第九条 紧张树公司的义务</div>
                        <div className="content">
                            1、紧张树公司同意通过互联网络，根据本协议约定，为用户提供紧张树服务。
                        </div>
                        <div className="content">
                            2、紧张树公司保存游戏虚拟货币的充值记录，保存期限为自用户充值之日起180日。
                        </div>
                        <div className="content">
                            3、在使用紧张树公司游戏虚拟货币的过程中，用户有权向紧张树公司要求协助调取游戏虚拟货币充值记录和转移记录，紧张树公司应当要求用户提供并核实与其注册身份信息相一致的个人有效身份信息或证明，紧张树公司核实用户所提供的个人有效身份信息或证明与所注册的身份信息相一致的，应当予以协助提供。用户没有提供其个人有效身份信息或证明，或者用户提供的个人有效身份信息或证明与所注册的身份信息不一致的，紧张树公司有权拒绝用户前述请求。
                        </div>
                        <div className="content">
                            4、若您使用紧张树公司认可的第三方账号作为游戏账号使用紧张树服务的，为此您还应严格遵守该第三方账号的相关用户协议和使用规则。如您因使用该第三方账号产生的相关问题（包括但不限于账号被盗、账号无法使用、账号处于异常状态等），您应自行联系该第三方进行解决，紧张树公司可视情况提供有限的、合理的协助。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十条 用户的义务</strong>
                        </div>
                        <div className="content">
                            1、用户应自行配备上网的所需设备，包括但不限于移动智能设备(包括但不限于手机、平板电脑)、电脑、无线路由器或其他必备上网装置。
                        </div>
                        <div className="content">
                            2、用户自行负担上网所支付的与此服务相关的电信费用、网络使用等费用。
                        </div>
                        <div className="content">
                            3、遵守本协议的约定、其他相关规则和制度的规定(包括但不限于紧张树公司在游戏官方网站或游戏内公布的规则等)；遵守中华人民共和国相关法律法规(如果用户是中华人民共和国境外的使用者，还应遵守所属国家或地区的法律法规)。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十一条 不正当游戏行为</strong>
                        </div>
                        <div className="content">
                            1、用户应当自行承担其所发布的信息内容所涉及的责任。特别地，用户不得发布下列内容：
                        </div>
                        <div className="content">
                            (1)反对中华人民共和国宪法所确定的基本原则的；
                        </div>
                        <div className="content">
                            (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                        </div>
                        <div className="content">(3)损害国家荣誉和利益的；</div>
                        <div className="content">
                            (4)煽动民族仇恨、民族歧视，破坏民族团结的；
                        </div>
                        <div className="content">
                            (5)破坏国家宗教政策，宣扬邪教和封建迷信的；
                        </div>
                        <div className="content">
                            (6)散布谣言，扰乱社会秩序，破坏社会稳定的；
                        </div>
                        <div className="content">
                            (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                        </div>
                        <div className="content">
                            (8)侮辱或者诽谤他人，侵害他人合法权益的；
                        </div>
                        <div className="content">
                            (9)宣扬、教唆使用外挂、私服以及木马的相关内容的；
                        </div>
                        <div className="content">
                            (10)发布任何经紧张树公司合理判断为不妥当或者紧张树公司未认可的软件、文件等在内的主页地址或者链接的；
                        </div>
                        <div className="content">
                            (11)含有中华人民共和国法律、行政法规禁止的其他内容的。
                        </div>
                        <div className="content">
                            2、用户不得通过不正当的手段或其他不公平的手段使用紧张树软件、紧张树服务或参与紧张树活动。
                        </div>
                        <div className="content">
                            用户不得干扰紧张树公司正常地提供软件和服务，包括但不限于：攻击、侵入紧张树公司的网站服务器，或集中时间段内以超出正常用户登录需求的高频率登录或尝试登录服务器从而使网站服务器过载；破解、修改紧张树公司提供的紧张树软件程序；攻击、侵入紧张树公司的游戏服务器/游戏服务器端程序或使游戏服务器过载；制作、发布、传播、使用任何形式的妨碍游戏公平性的辅助工具或程序(包括但不限于“外挂”,
                            “外挂”是指独立于游戏软件之外的，能够在游戏运行的同时影响游戏操作的所有程序，包括但不限于模拟用户操作、改变操作环境、修改数据等一切类型）。用户不得：利用程序的漏洞和错误(Bug)破坏游戏的正常进行或传播该漏洞或错误(Bug)；不合理地干扰或阻碍他人使用紧张树公司所提供的软件和服务；通过异常或者非法的方式使用紧张树公司的软件(包括但不限于利用紧张树软件登录游戏私服)；使用异常的方法登录游戏（包括但不限于使用非紧张树公司开发、授权或认可的第三方软件、系统登录游戏）、使用网络加速器等外挂软件或机器人程序等恶意破坏服务设施、扰乱正常服务秩序的行为；修改、翻译、注释、整理、汇编、演绎紧张树软件；利用本软件或者线上游戏系统可能存在的技术缺陷或漏洞而以各种形式为自己及他人牟利（包括但不限于复制游戏虚拟物品等）或者从事其他不正当行为等。
                        </div>
                        <div className="content">
                            用户同意以游戏程序中的监测数据作为判断用户是否有通过使用外挂程序等方法进行游戏作弊行为的依据。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十二条 服务的中断、中止、终止</strong>
                        </div>
                        <div className="content">1、对用户服务的中止与终止</div>
                        <div className="content">
                            (1)用户有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，紧张树公司应当立即终止对用户提供服务。
                        </div>
                        <div className="content">
                            (2)用户在接受紧张树公司服务时实施不正当行为的，紧张树公司有权终止对用户提供服务。该不正当行为的具体情形应当在本协议中有明确约定或属于紧张树公司事先明确告知的应被终止服务的禁止性行为，否则，紧张树公司不得终止对用户提供服务。
                        </div>
                        <div className="content">
                            (3)用户提供虚假注册身份信息，或实施违反本协议的行为，紧张树公司有权中止对用户提供全部或部分服务；紧张树公司采取中止措施应当通知用户并告知中止期间，中止期间应该是合理的，中止期间届满紧张树公司应当及时恢复对用户的服务。
                        </div>
                        <div className="content">
                            (4)紧张树公司根据本款(1)、(2)、(3)项的约定中止或终止对用户提供部分或全部服务的，紧张树公司应负举证责任。
                        </div>
                        <div className="content">
                            2、对游戏服务器运营的中断、中止与终止
                        </div>
                        <div className="content">
                            (1)为了游戏服务器的正常运行，紧张树公司需要定期对游戏服务器进行停机维护或针对突发事件进行紧急停机维护；因上述情况而造成的正常服务中断、中止，请您予以理解，紧张树公司则有义务尽力将中断时间限制在最短时间内。
                        </div>
                        <div className="content">
                            发生下列情形之一时，紧张树公司有权终止或中断游戏服务器所提供之全部或部分服务，对因此而产生的不便或损害，紧张树公司对用户或第三人均不承担任何责任：
                        </div>
                        <div className="content">
                            ①定期检查或施工，更新软硬件等，紧张树公司有权暂停服务，但紧张树公司会尽快完成维护、更新工作；
                        </div>
                        <div className="content">
                            ②游戏服务器遭受损害，无法正常运作；
                        </div>
                        <div className="content">
                            ③突发性的软硬件设备与电子通信设备故障；
                        </div>
                        <div className="content">
                            ④网络提供商线路或其他故障；
                        </div>
                        <div className="content">
                            ⑤在紧急情况之下依照法律的规定或为用户及第三者之人身安全；
                        </div>
                        <div className="content">
                            ⑥第三方原因或其他不可抗力的情形。
                        </div>
                        <div className="content">
                            (2)紧张树公司保留在其认为有必要的情况下，终止或部分终止提供紧张树服务的权利，终止前将提前60天予以公告。
                        </div>
                        <div className="content">
                            不管由于何种原因终止紧张树服务，用户应采取相应的措施自行处理游戏虚拟物品，包括但不限于注销或停止使用用户帐号、游戏虚拟物品等相关事宜。用户不得因全面终止紧张树服务而要求紧张树公司承担除用户已经购买但尚未使用的游戏虚拟货币外任何形式的赔偿或补偿责任，包括但不限于因不再能继续使用用户帐号、游戏虚拟物品等而要求的赔偿。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十三条 战斗系统特别说明</strong>
                        </div>
                        <div className="content">
                            特定紧张树服务中可能含有战斗系统，在战斗系统里，用户与用户之间可以自由对战。
                            就该类含有战斗系统的紧张树服务，您一旦点击“接受”（或其他具有同样含义的词，如“同意”等）并安装相应的紧张树软件，或者注册、开始使用及/或继续使用相应的紧张树软件或紧张树服务，将视为您同意游戏战斗系统中的所有自由对战行为，并同意遵守自由对战的游戏规则。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十四条 单机游戏特别说明</strong>
                        </div>
                        <div className="content">
                            主体为单机游戏的紧张树服务中，充值系统仅在接入互联网或移动通信网（短代支付等）后方可使用，如您使用充值系统进行充值，将通过互联网或移动通信网（短代支付等）确认充值信息。主体为单机游戏的紧张树服务中，全部游戏数据将保存在您的移动智能设备上，一旦您卸载、重装紧张树软件，或您的移动智能设备损坏导致紧张树软件无法正常启动的，您的全部游戏数据将无法恢复。如您需将游戏数据保存在游戏服务器中的，需要联网进行数据保存操作。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第十五条 对玩家违规行为的基本处理方法的释义
                            </strong>
                        </div>
                        <div className="content">
                            请您相信，玩家守则的目的是为了维护广大玩家朋友的利益。如果您因为违反以下守则而受到处罚，也是为了广大玩家朋友（包括您）的利益。
                        </div>
                        <div className="content">
                            对玩家违规行为的基本处理方法的释义
                        </div>
                        <div className="content">
                            ◆ 强制离线：强制玩家退出游戏回到登录界面。
                        </div>
                        <div className="content">
                            ◆ 角色封停：临时或永久禁止角色登录游戏 。
                        </div>
                        <div className="content">
                            ◆ 账号封停：临时或永久禁止账号使用 。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十六条 游戏内违规处罚内容</strong>
                        </div>
                        <div className="content">
                            一、正确处理玩家与玩家间的关系
                        </div>
                        <div className="content">
                            1、盗号（包括但不限于盗取账号、游戏数据、玩家个人资料）及盗号相关的行为（协助盗号者操作及转移游戏道具等行为），根据实际情况和情节严重程度将有可能受到以下处罚：冻结或者回收游戏道具、对账号进行倒扣数值、关入监狱、封闭账号、承担法律责任。
                        </div>
                        <div className="content">
                            2、凡是通过各种方式、行为散布或传播木马、外挂、病毒、私服等信息，根据实际情况和情节严重程度将有可能受到以下处罚：警告、暂时关入监狱、封闭账号。
                        </div>
                        <div className="content">
                            二、正确处理玩家与游戏间的关系：
                        </div>
                        <div className="content">
                            1、散布有损国家尊严和荣誉，危害社会秩序的言行，根据实际情况和情节严重程度将有可能受到以下处罚：禁言、强制下线、关入监狱、封闭账号、承担法律责任（交由主管国家机关处理）。
                        </div>
                        <div className="content">
                            2、利用各种方式攻击或入侵游戏服务器，或使用异常的方法登录紧张树所运营代理的游戏世界服务器，根据实际情况和情节严重程度将有可能受到以下处罚：封闭账号、承担法律责任。
                        </div>
                        <div className="content">
                            3、使用第三方软件恶性破坏服务设施，扰乱正常服务秩序的行为（包括不限于利用编译或反编译程序修改游戏资料、使用外挂加速器等作弊性质的外挂软件或机器人程序），根据实际情况和情节严重程度将有可能受到以下处罚：封闭账号、承担法律责任。
                        </div>
                        <div className="content">
                            4、利用系统的异常或BUG（直接或间接利用游戏BUG、程序漏洞等）获利或扰乱游戏秩序，
                            根据实际情况和情节严重程度将有可能受到以下处罚：强制下线、回收因BUG所获得的利益、封闭角色、封闭账号、承担法律责任。
                        </div>
                        <div className="content">
                            5、利用游戏提供的功能破坏游戏平衡，对其他玩家或本公司的正当利益造成损害的行为（包括但不限于打钱工作室，非法线下交易等），根据实际情况和情节严重程度将有可能受到以下处罚：封闭账号、承担法律责任。
                        </div>
                        <div className="content">
                            6、利用游戏提供的功能进行非法实物交易等违反国家法律法规的行为。紧张树不认可在非紧张树提供或认可的交易平台所产生的交易结果，玩家从非紧张树
                            提供或认可的交易平台所获得的游戏道具或账号将被认定为来源不符合游戏规则，非法交易根据实际情况和情节严重程度将有可能受到以下处罚：警告、冻结或者回收游戏道具或银币、对账号进行倒扣数值、强制下线、封闭角色、封闭账号、承担法律责任。
                        </div>
                        <div className="content">
                            7、凡是通过各种方式、行为冒充紧张树公司职员以及GM、系统向其他玩家散布或传播虚假信息，以及试图利用冒充官方进行欺诈，根据实际情况和情节严重程度将有可能受到以下处罚：禁言、封闭账号。
                        </div>
                        <div className="content">
                            8、无视GM警告及妨碍运营的行为（包括但不限于辱骂GM、欺骗或试图欺骗GM、违反或忽视GM做出的提示、骚扰GM），根据实际情况和情节严重程度将有可能受到以下处罚：警告、禁言、封闭账号。
                        </div>
                        <div className="content">
                            9、虚假或恶意投诉及举报的行为，根据实际情况和情节严重程度将有可能受到以下处罚：警告、封闭账号。
                        </div>
                        <div className="content">
                            10、通过第三方软件或在第三方软件的辅助下使用紧张树的产品或接受紧张树的服务，破坏游戏规则和违反游戏公平性的行为（包括不限于使用脚本、多开工具等）：根据实际情况和情节严重程度将有可能受到以下处罚：封闭账号。
                        </div>
                        <div className="content">三、其他</div>
                        <div className="content">
                            其他以上未列举的妨碍其他玩家正常游戏、对游戏运营造成不良影响的言行，根据实际情况和情节严重程度将有可能受到以下处罚：警告、禁言、强制下线、封闭账号、删除角色、承担法律责任（交由主管国家机关处理）。
                        </div>
                        <div className="content">
                            GM将有权立即暂停任何可能危害大多数玩家的利益的游戏账号的上线权利而不先行告之。
                            所有GM 都将严格按照中立和公正原则处理问题。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第十七条 游戏管理员(以下简称“GM”)交互管理规则
                            </strong>
                        </div>
                        <div className="content">
                            1、在游戏过程中，玩家可能会因为种种原因与GM进行交流。紧张树公司将就具体游戏提供游戏内的在线提问作为玩家和GM之间的交流平台。如果在游戏中遇到问题，玩家可以通过在线提问提交问题，它将给玩家提供快速、优质的服务。
                        </div>
                        <div className="content">
                            2、希望GM与玩家的交流能对玩家有所帮助，但请玩家遵守相关规定。紧张树公司将严肃处理以下行为：
                        </div>
                        <div className="content">
                            (1)冒充系统或GM：在游戏中，可能会存在某些玩家冒充系统或GM的行为。如若发现，您可以通过游戏论坛向GM举报，并协助查证。一经查实，紧张树公司将对其采取严厉的处罚措施。
                        </div>
                        <div className="content">
                            (2)欺骗或试图欺骗GM：在游戏中，玩家不要试图利用紧张树公司对您的信任，做出某些欺骗或试图欺骗GM的行为。如若发现，紧张树公司将对该等玩家采取严厉的处罚措施。试图欺骗GM的行为包括但不限于误导GM、拒绝提供信息、提供虚假信息以及任何试图“诈骗”GM的行为。
                        </div>
                        <div className="content">
                            (3)违反或忽视GM做出的提示：在游戏中，为了确保大多数玩家的共同利益，维护正常的游戏秩序，GM可能会提示玩家执行某些操作或停止执行某些操作，任何忽视或阻挠该项工作的行为都可能导致玩家游戏人物受到处罚。因此，玩家应当保持合作态度。
                        </div>
                        <div className="content">
                            (4)干扰GM工作：在游戏中，如若出现以下行为，紧张树公司将对其采取严厉的处罚措施：
                        </div>
                        <div className="content">
                            ①向GM索取任何虚拟游戏物品（包括但不限于游戏资源、装备等）；
                        </div>
                        <div className="content">
                            ②频繁呼叫GM或发送无实质性内容的请求；
                        </div>
                        <div className="content">
                            ③反复向GM发送已解答或解决问题的帮助请求。
                        </div>
                        <div className="content">
                            (5)辱骂或恶意攻击GM：在游戏中，不允许出现辱骂或恶意攻击GM的言行。如若发现，紧张树公司将对其采取严厉的处罚措施。
                        </div>
                        <div className="content">
                            3、若发现玩家违反本条第2款中的任何一项，根据情况将可能受到以下处罚：警告；禁言；暂时冻结玩法；永久冻结玩法；暂时禁止登录；永久禁止登录；强制离线；封停账号；删除档案；删除论坛帖子；屏蔽论坛账号；暂时中断服务；承担法律责任。
                        </div>
                        <div className="content">
                            4、取回密码：如遇密码遗忘、丢失等情况，请玩家第一时间尝试自行登录“紧张树用户中心”页面进行操作取回。如无法取回，则可收集取回密码的相关资料联系GM，紧张树公司将有专门的工作人员为玩家查证处理。
                        </div>
                        <div className="content">
                            玩家以紧张树认可的合作方帐号登陆游戏的，如遇密码遗忘、丢失等情况，则请玩家第一时间联系提供该帐号注册服务的主体（即紧张树认可的合作方）进行处理。
                        </div>
                        <div className="content">
                            温馨提醒：任何GM和紧张树公司工作人员均不会在游戏、论坛中向玩家索要通行证密码、安全码等信息，请玩家注意识别，以免造成不必要的损失。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第十八条 利用游戏Bug以及第三方软件处理规则
                            </strong>
                        </div>
                        <div className="content">
                            1、游戏BUG指游戏系统、程序、设定等方面存在的漏洞或不合理的现象。
                        </div>
                        <div className="content">
                            玩家有义务向GM报告在游戏中出现的BUG。严禁玩家直接或间接利用游戏BUG、程序漏洞等获利或扰乱游戏秩序，或者利用BUG、漏洞以达到个人目的。如果玩家有该等行为，一经查实，可能受到如下处罚措施：没收玩家利用BUG进行游戏而直接、间接获得之不正当利益；倒扣数值（包括但不限于经验值）；撤销交易；扣除违规交易所得；交易功能限制；强制离线；暂时冻结玩法；永久冻结玩法；暂时禁止登录；永久禁止登录；删除档案；承担法律责任。
                        </div>
                        <div className="content">
                            2、未经许可的第三方软件指可用于在游戏中获取优势，但不属于相应紧张树软件一部分的任何文件或程序，包括作弊性质的外挂以及相关辅助性质的外挂等（包括但不限于自动升级、自动练级、加速性质、或其他超出游戏设定范围的操作）。如若发现使用作弊性质的外挂以及相关辅助性质的外挂等，我们将对其采取严厉的处罚措施，包括：没收玩家利用第三方软件进行游戏而直接、间接获得之不正当利益；倒扣数值（包括但不限于经验值）；暂时冻结玩法；永久冻结玩法；强制离线；暂时禁止登录；永久禁止登录；删除档案；承担法律责任。
                        </div>
                        <div className="content">
                            任何与紧张树软件同时使用的外部第三方软件可能会对游戏产生不良的影响。使用外部应用程序可能造成的主要后果是数据传送不流畅，导致玩家频繁掉线或卡机。外部应用软件可能使玩家移动智能设备（包括但不限于手机、平板电脑）、电脑等感染病毒、间谍软件和其他不明程序的侵扰。第三方软件还可能窃取玩家存储在移动智能设备（包括但不限于手机、平板电脑）、电脑中的信息，例如账号、密码等个人信息。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第十九条 盗号与盗号相关行为处理规则</strong>
                        </div>
                        <div className="content">
                            为保障玩家能愉悦的体验游戏，保障玩家的正当利益不受到侵害，紧张树公司对盗号及盗号相关行为（包括但不限于盗取用户帐号、游戏数据、玩家个人资料、协助盗号者操作等行为）将予以严厉打击和处罚。一经查证属实或应有权机关的要求，紧张树公司有权视具体情况立即采取暂时限制游戏行为、暂时禁止登录、永久禁止登录、封停账号、删除档案等处罚措施，情节严重的，紧张树公司保留对涉案玩家追究法律责任的权利。
                        </div>
                        <div className="content">
                            在查证玩家被盗事件的过程中，为能够快速调查、处理问题，紧张树公司可能会请相关玩家协助调查，其方式包括但不限于禁止登录调查、线上交流、线下交流等。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第二十条 与充值相关的不诚信行为处理规则
                            </strong>
                        </div>
                        <div className="content">
                            诚实信用也是用户在充值相关行为中应遵循的基本原则。用户成功为紧张树服务充值获得虚拟物品使用权或增值服务（以下统称“充值收益”）后，如对紧张树服务有任何误会、不满意、建议或其他诉求的，可随时通过客服专区或客服电话通知紧张树公司。用户未选择该等方式而是另行进行退款操作的，应立即通过客服专区或客服电话通知紧张树公司扣除相应的充值收益；如用户进行退款操作后仍占有充值服务的属不诚信行为，紧张树公司将予以严厉打击和处罚，包括但不限于如下一项或几项：警告、倒扣数值、冻结或回收游戏虚拟物品、暂时冻结玩法、永久冻结玩法、强制离线、封停账号、删除档案及采取其他技术措施防止用户从事该等行为；情节严重的，紧张树公司保留追究用户法律责任（包括但不限于民事责任、刑事责任）的权利。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十一条 数据异常处理规则</strong>
                        </div>
                        <div className="content">
                            如果紧张树公司发现用户数据异常或者存在违法或者其他不正当行为，“其他不正当行为”包括但不限于：
                            （1）利用bug或非正常游戏方式提升游戏角色数据、游戏内的角色排名的行为；
                            紧张树公司有权进行调查并采取相应措施，包括但不限于：
                        </div>
                        <div className="content">(1)删除游戏虚拟物品；</div>
                        <div className="content">(2)没收不正当利益；</div>
                        <div className="content">(3)数据回退或清零；</div>
                        <div className="content">(4)暂时禁止登录；</div>
                        <div className="content">(5)永久禁止登录；</div>
                        <div className="content">(6)暂时隔离；</div>
                        <div className="content">(7)永久隔离；</div>
                        <div className="content">(8)强制离线；</div>
                        <div className="content">
                            (9)封停直至删除用户帐号等。用户无权因此要求紧张树公司承担任何责任。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第二十二条 牟取不正当利益行为处理规则
                            </strong>
                        </div>
                        <div className="content">
                            用户理解并同意，紧张树公司向用户提供紧张树服务，是为了让用户体验丰富多彩的游戏内容，而非利用紧张树服务赚取现实世界的金钱或牟取经济利益。而用户不以体验游戏内容为目的，在游戏中主要进行交易和牟利的行为，会破坏游戏内经济系统的公平性或平衡性，从而对其他用户的游戏体验、游戏本身造成不利影响。
                        </div>
                        <div className="content">
                            因此，用户承诺不以营利为目的从事游戏行为，任何以营利为目的从事游戏行为将被视为牟取不正当利益，紧张树公司有权对牟取不正当利益的用户同时采取以下措施：对所有参与牟取不正当利益的用户帐号（包括但不限于获得、出售游戏虚拟物品的账号）进行倒扣数值、收回游戏虚拟物品、暂时冻结玩法、永久冻结玩法、强制离线、暂时隔离、永久隔离、封停帐号、删除档案及采取其他技术措施防止用户从事该等行为；情节严重的，紧张树公司保留追究用户法律责任的权利。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十三条 角色保护规则</strong>
                        </div>
                        <div className="content">
                            若您报告角色被盗、遭遇黑客行为或出现其他异常情况，或者紧张树公司发现您的角色存在被盗、共享或者被滥用等迹象或者其他异常情况，为避免您的游戏角色、游戏虚拟物品等遭受不法侵害，紧张树公司将会对您的角色进行调查，并根据情节，在调查期间对您的角色采取保护性措施（包括但不限于暂时冻结角色）。请您根据紧张树公司的指引，提交相关材料以配合紧张树公司的调查，以便尽快解除您角色的保护性措施。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十四条 游戏画面使用规则</strong>
                        </div>
                        <div className="content">
                            未经紧张树公司事先书面允许，您不得通过第三方软件、网站、平台公开全部或部分展示、复制、传播、播放紧张树服务中的游戏画面，否则紧张树公司将有权根据您的违约情节，采取各种处理措施，包括但不限于如下一项或几项，且紧张树公司保留进一步追究您法律责任的权利：暂时禁止登录、强制离线、封停账号。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十五条 损害赔偿</strong>
                        </div>
                        <div className="content">
                            如您在游戏内的违规行为给紧张树公司或第三方造成损害的，您应承担相应的法律责任，而且针对自身的损害紧张树公司有权采取一切合理的行动，包括但不限于收回游戏虚拟物品等，紧张树公司并有权向您索回损失额并要求您承担紧张树公司合理的追索费用。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十六条 不可抗力</strong>
                        </div>
                        <div className="content">
                            紧张树公司对不可抗力导致的损失不承担责任。本协议所称不可抗力包括：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机、移动设备或互联网相关技术缺陷、互联网覆盖范围限制、计算机、移动设备病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十七条 链接及广告信息</strong>
                        </div>
                        <div className="content">
                            紧张树公司在游戏官方网站、游戏官方论坛、游戏中所提供的所有链接，可能链接到其他个人、公司或组织的网站，提供该等网站的目的，是便利用户自行搜索或取得相关信息，紧张树公司对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、实时性或可信度。这些个人、公司或组织与紧张树公司间不存在任何雇佣、委任、代理、合伙或其他类似的关系。
                        </div>
                        <div className="content">
                            紧张树公司在游戏官方网站、游戏官方论坛、游戏内可能刊登商业广告或其他推广活动信息。这些广告或推广活动信息是由广告商或商业服务提供者提供，并承担相应责任，紧张树公司仅提供刊登内容的媒介。用户通过该等链接的网站或广告所购买的商品或服务，其交易行为仅存在于用户与该商品或服务的提供者之间，与紧张树公司无关，紧张树公司不就用户与该商品或服务的提供者之间所产生的交易行为承担任何法律责任，用户应予以特别留意。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第二十八条 信息的储存和安全及服务的限制
                            </strong>
                        </div>
                        <div className="content">
                            紧张树公司对本协议下所提供的服务将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任。紧张树公司保留判定用户的行为是否符合本协议约定的权利，如果用户违背了本协议的约定，紧张树公司有权中断依据本协议对用户提供的服务。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第二十九条 条款变更</strong>
                        </div>
                        <div className="content">
                            紧张树公司有权在必要的时候修改本协议条款，敬请用户定期查询有关内容。用户如继续使用本协议涉及的服务，则视为对修改内容的同意；用户在不同意修改内容的情况下，应当卸载紧张树软件、终止本协议并停止使用紧张树服务。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第三十条 游戏数据</strong>
                        </div>
                        <div className="content">
                            在用户参加游戏过程中产生并储存于紧张树公司服务器中的任何数据信息，包括但不限于账号数据信息、角色数据信息、等级及虚拟物品数据信息等（但用户的姓名、身份证号、电话号码等个人身份数据信息除外），属于游戏软件的一部分，由紧张树公司所有并进行管理，用户有权在遵守游戏规则及法律法规规定的前提下通过正当的途径对属于自身用户帐号的数据信息进行修改、转移、抛弃等。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第三十一条 通知</strong>
                        </div>
                        <div className="content">
                            紧张树公司所有发给用户的通知可通过游戏内、游戏官方网站或游戏官方论坛重要页面的公告、电子邮件或常规的电话、易信、信件等形式传送。同时，用户在此同意紧张树公司可以向其电子邮箱、电话、易信、信件发送商业信息。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                第三十二条 用户所提供内容的知识产权及授权
                            </strong>
                        </div>
                        <div className="content">
                            紧张树公司尊重知识产权并注重保护用户享有的各项权利。在紧张树服务中，您可能需要通过上传、发布等各种方式向紧张树公司提供内容。在此情况下，您仍然享有此等内容的完整知识产权。
                        </div>
                        <div className="content">
                            您在此明确同意，您在提供前述内容的同时，不可撤销地授予紧张树公司及其关联公司一项全球性的、永久的免费许可，允许紧张树公司及其关联公司使用、传播、复制、修改、再许可、翻译、出版、表演及展示此等内容，以及使用此等内容创作衍生作品。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>第三十三条 其他约定</strong>
                        </div>
                        <div className="content">
                            为避免争议，本协议涉及到的有关数据、有关技术方面的问题，用户同意以紧张树公司服务器所储存的数据作为判断标准。紧张树公司保证该数据的真实性。
                        </div>
                        <div className="content">
                            本协议所有条款的标题仅为方便阅读，本身并无实际涵义，不能作为本协议及相关条款涵义解释的依据。
                        </div>
                        <div className="content">
                            如本协议中的任何条款因任何原因被判定为完全或部分无效或不具有执行力的，本协议的其他条款仍应有效并且有执行力。
                        </div>
                        <div className="content">
                            紧张树公司不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响紧张树公司在将来行使该权利。
                        </div>
                        <div className="blank" />
                        <div className="blank" />
                        <div className="blank" />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default User;
