import React, { useState, useEffect } from 'react';
import '@styles/menu.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Homepage from './homepage';
import Character from './character';
import Feature from './feature';
import Concept from './concept';
import News from './news';
import Last from './last';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Dashboard = () => {
    const [selectedPage, setSelectedPage] = useState(0);
    const [swiper, setSwiper] = useState(null);
    const [changeable, setChangeable] = useState(true);
    const [direction, setDirection] = useState('');
    const [showRightSide, setShowRightSide] = useState(false);

    const pages = [
        { id: 'homepage', label: '首页' },
        { id: 'character', label: '角色介绍' },
        { id: 'feature', label: '游戏特色' },
        { id: 'concept', label: '概念相关' },
        { id: 'news', label: '新闻资讯' },
    ];

    useEffect(() => {
        window.addEventListener('wheel', function (e) {
            if (e.deltaY > 0) {
                setDirection('down');
            } else {
                setDirection('up');
            }
        });
    }, []);

    useEffect(() => {
        if (swiper !== null && changeable && direction !== '') {
            setChangeable(false);
            if (direction === 'down') {
                swiper.slideNext();
            } else if (direction === 'up') {
                swiper.slidePrev();
            }
            setTimeout(() => {
                setChangeable(true);
            }, 200);
        }

        setDirection('');
    }, [direction, swiper]);

    return (
        <div style={{ background: 'black' }}>
            <Swiper
                className="menu-swiper-container"
                slidesPerView={1}
                direction="vertical"
                speed={500}
                mousewheel={false}
                keyboard={{ enabled: true, onlyInViewport: true }}
                onSlideChange={(e) => setSelectedPage(e.activeIndex)}
                onSwiper={(swiper) => {
                    setSwiper(swiper);
                }}
            >
                <SwiperSlide className="menu-swiper-slide">
                    <Homepage />
                </SwiperSlide>
                <SwiperSlide className="menu-swiper-slide">
                    <Character />
                </SwiperSlide>
                <SwiperSlide className="menu-swiper-slide">
                    <Feature />
                </SwiperSlide>
                <SwiperSlide className="menu-swiper-slide">
                    <Concept />
                </SwiperSlide>
                <SwiperSlide className="menu-swiper-slide">
                    <News />
                </SwiperSlide>
                <SwiperSlide className="menu-swiper-slide">
                    <Last />
                </SwiperSlide>
            </Swiper>
            {/* <div className="side">
                {pages.map((v, idx) => (
                    <div
                        className="side-item"
                        key={v.id}
                        onClick={() => swiper.slideTo(idx)}
                    >
                        <div
                            className={
                                selectedPage === idx ? 'selected' : 'unselected'
                            }
                        >
                            {v.label}
                        </div>
                    </div>
                ))}
            </div> */}
            <div className="nav">
                <a
                    href="https://www.biligame.com/detail/?id=110224"
                    target="text"
                    className="ico ico-bilibili ico-normal"
                />
                <a
                    href="https://www.taptap.cn/app/273409"
                    target="text"
                    className="ico ico-taptap ico-normal"
                />
            </div>
            <div
                className="ico-small-side"
                onClick={() => setShowRightSide((pre) => !pre)}
            />
            <div
                className={
                    showRightSide ? 'right-side right-side-show' : 'right-side'
                }
            >
                {showRightSide ? (
                    <div>
                        {/* {pages.map((v, idx) => (
                            <div
                                className="small-side-item"
                                key={v.id}
                                onClick={() => swiper.slideTo(idx)}
                            >
                                <div
                                    className={
                                        selectedPage === idx
                                            ? 'selected'
                                            : 'unselected'
                                    }
                                >
                                    {v.label}
                                </div>
                            </div>
                        ))} */}
                        <div>
                            <a
                                href="https://www.biligame.com/detail/?id=110224"
                                target="text"
                                className="ico ico-bilibili ico-small"
                            />
                        </div>
                        <a
                            href="https://www.taptap.cn/app/273409"
                            target="text"
                            className="ico ico-taptap ico-small"
                        />
                    </div>
                ) : null}
            </div>
            {selectedPage !== 5 ? <div className="next-page" /> : null}
        </div>
    );
};
export default Dashboard;
