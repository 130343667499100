import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Upload, message } from 'antd';
import { PlusOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { Post } from '@lib/request';
import COS from 'cos-js-sdk-v5';
import '@styles/index.css';

const Report = () => {
    const { RangePicker } = DatePicker;
    const { TextArea } = Input;
    const [fileList, setFileList] = useState([]);
    const [description, setDescription] = useState('');
    const [contact, setContact] = useState('');
    const [fileAddrMap, setFileAddrMap] = useState({});
    const [success, setSuccess] = useState(false);
    const [disable, setDisable] = useState(false);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const unload = (file) => {
        Post('/cos/auth', { filename: file.name }, (res) => {
            const { status, data } = res;
            if (status === 200) {
                const { Credential, Basic } = data;
                const { Credentials, StartTime, ExpiredTime } = Credential;
                const { TmpSecretId, TmpSecretKey, Token } = Credentials;
                const { Bucket, Region, Key } = Basic;
                const cos = new COS({
                    SecretId: TmpSecretId,
                    SecretKey: TmpSecretKey,
                    SecurityToken: Token,
                    StartTime,
                    ExpiredTime,
                });
                cos.uploadFile(
                    {
                        Bucket,
                        Region,
                        Key,
                        Body: file, // 要上传的文件对象。
                    },
                    function (err, data) {
                        if (err) {
                            message.error('上传失败');
                            setFileList((pre) =>
                                pre.filter((v) => v.uid !== file.uid)
                            );
                        } else {
                            const { statusCode, Location } = data;
                            if (statusCode === 200) {
                                setFileAddrMap((pre) => {
                                    return {
                                        ...pre,
                                        [file.uid]: Location,
                                    };
                                });
                            } else {
                                message.error('上传失败');
                                setFileList((pre) =>
                                    pre.filter((v) => v.uid !== file.uid)
                                );
                            }
                        }
                    }
                );
            } else {
                message.error('上传失败');
            }
        });
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('你只能上传 JPG/PNG 文件');
        }
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            message.error('图片大小不得超过 20MB!');
        }

        if (isJpgOrPng && isLt20M) {
            unload(file);
            return true;
        } else {
            return false;
        }
    };

    const parseFileList = () => {
        return fileList.map((v) => {
            if (fileAddrMap[v.uid] !== undefined) {
                v.status = 'done';
            }
            return v;
        });
    };

    const report = () => {
        if (description === '') {
            message.error('请详细描述您的问题');
            return;
        }
        let images = [];
        if (fileList.length > 0) {
            fileList.forEach((v) => {
                if (fileAddrMap[v.uid] !== undefined) {
                    images.push(fileAddrMap[v.uid]);
                }
            });
        }
        const contract = contact;
        Post(
            '/report',
            {
                uid: queryParams.get('uid'),
                token: queryParams.get('token'),
                description,
                contract,
                images,
            },
            (res) => {
                const { status } = res;
                if (status === 200) {
                    setSuccess(true);
                }
            },
            (err) => {
                const { response } = err;
                const { status } = response;
                if (status === 401) {
                    message.error('验证失败');
                } else if (status === 400) {
                    message.error('提交失败');
                } else if (status === 429) {
                    message.error('提交过于频繁,请稍后再试');
                }
            }
        );
    };

    useEffect(() => {
        Post(
            '/auth',
            { uid: queryParams.get('uid'), token: queryParams.get('token') },
            (res) => {
                const { status, data } = res;
                if (status !== 200) {
                    setDisable(true);
                    message.error('验证失败');
                }
            }
        );
    }, []);

    return (
        <div className="bg">
            {success ? (
                <div style={{ textAlign: 'center' }}>
                    <div style={{ marginTop: '30vh' }}>
                        <CheckCircleFilled
                            style={{ fontSize: 90, color: 'green' }}
                        />
                    </div>
                    <div
                        style={{
                            fontSize: 20,
                            margin: '20px 0 5px 0',
                            fontWeight: 600,
                        }}
                    >
                        提交成功
                    </div>
                    <div style={{ fontSize: 16, fontWeight: 400 }}>
                        感谢您的反馈，我们会尽快为您处理
                    </div>
                    <Button
                        style={{
                            marginTop: '20px',
                            width: '100px',
                            color: 'blue',
                            border: '1px solid blue',
                        }}
                        onClick={() => setSuccess(false)}
                    >
                        返回
                    </Button>
                </div>
            ) : (
                <>
                    <div
                        style={{
                            padding: '20px 35px',
                            height: '90vh',
                            overflowY: 'scroll',
                        }}
                    >
                        <Form layout="horizontal" disabled={disable}>
                            <Form.Item
                                label="问题描述"
                                name="问题描述"
                                rules={[
                                    {
                                        required: true,
                                        message: '请详细描述您的问题',
                                    },
                                ]}
                            >
                                <TextArea
                                    showCount
                                    maxLength={1000}
                                    autoSize={{ minRows: 3, maxRows: 10 }}
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="联系方式">
                                <Input
                                    placeholder="手机号码/邮箱"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item label="">
                                <div>图片上传</div>
                                <div style={{ fontSize: 13 }}>
                                    最多上传10张图片，每张图片容量不超过20MB
                                </div>
                                <Upload
                                    listType="picture-card"
                                    beforeUpload={beforeUpload}
                                    fileList={parseFileList()}
                                    onChange={(e) => setFileList(e.fileList)}
                                    customRequest={() => {}}
                                    maxCount={10}
                                    multiple
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div
                                            style={{
                                                marginTop: 8,
                                            }}
                                        >
                                            点击添加
                                        </div>
                                    </div>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </div>
                    <div
                        style={{
                            width: '100vw',
                            height: '58px',
                            padding: '0 35px',
                            position: 'absolute',
                            bottom: '0px',
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ height: '40px' }}
                            block
                            onClick={report}
                            disabled={disable}
                        >
                            提交
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
export default Report;
