import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from '@pages/dashboard/menu';
import NotFound from '@pages/notFound';
import Policy from '@pages/policy/policy';
import User from '@pages/policy/user';
import Privacy from '@pages/policy/privacy';
import Children from '@pages/policy/children';
import Report from '@pages/report';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
    <Router>
        <Switch>
            <Route path="/user" component={User} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/children" component={Children} />
            <Route path="/policy" component={Policy} />
            <Route path="/report" component={Report} />
            <Route path="/" component={Dashboard} />
            <Route path="*" component={NotFound} />
        </Switch>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
