import React from 'react';
import { Row, Col } from 'antd';

const Privacy = () => {
    return (
        <div>
            <Row>
                <Col lg={{ span: 18, offset: 3 }}>
                    <div style={{ paddingTop: '10vh' }}>
                        <div className="policy-title">紧张树科技隐私政策</div>
                        <div className="right-content">
                            更新日期：2023年4月13日
                        </div>
                        <div className="right-content">
                            生效日期：2023年4月13日
                        </div>
                        <div className="content">
                            <strong>概要</strong>
                        </div>
                        <div className="content">
                            欢迎您选择由杭州紧张树科技有限公司（以下简称“我们”或“紧张树科技”）提供的游戏！除《紧张树科技用户协议》外，我们还将通过《紧张树科技隐私政策》向您进一步细化说明您在使用本游戏时我们收集、使用、存储和共享个人信息的情况，以及您所享有的相关权利等事宜，本政策是《紧张树科技用户协议》的组成部分。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切冲突法的适用。如双方就本协议的内容或其执行发生任何争议（包括但不限于合同或者其他财产性权益纠纷），双方应友好协商解决；协商不成时，双方同意交由设杭州紧张树科技有限公司所在地人民法院管辖并处理。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            您可以通过阅读完整版《紧张树科技隐私政策》，了解个人信息类型与用途的对应关系等更加详尽的个人信息处理规则。请您务必仔细阅读并透彻理解本政策，特别是以黑色加粗、下划线方式提示您注意的条款，您应重点阅读并在确认充分理解及同意后再开始使用。如果您继续使用我们的服务，即表示您同意我们按照本政策收集、使用、储存和披露您的相关信息。
                        </div>
                        <div className="content">
                            一、我们如何收集和使用个人信息
                        </div>
                        <div className="content">
                            二、我们如何使用COOKIES或同类技术
                        </div>
                        <div className="content">
                            三、我们可能分享、转让或披露的个人信息
                        </div>
                        <div className="content">
                            四、我们如何储存和保护个人信息
                        </div>
                        <div className="content">五、如何管理您的个人信息</div>
                        <div className="content">六、第三方服务</div>
                        <div className="content">七、年龄限制</div>
                        <div className="content">八、通知和修订</div>
                        <div className="content">九、如何联系我们</div>
                        <div className="blank" />
                        <div className="content">
                            <strong>一、我们如何收集和使用个人信息</strong>
                        </div>
                        <div className="content">
                            当我们提供多项需收集个人信息的业务功能时，我们不会违背您的自主意愿，强迫您接受紧张树科技游戏服务所提供的业务功能及相应的个人信息收集请求，亦不会通过自启动和关联启动方式收集或使用您的个人信息。在您使用紧张树科技游戏服务的过程中，紧张树科技会按照如下方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以及保障您的账户安全：
                        </div>
                        <div className="content">
                            1.1
                            当您注册或使用紧张树科技游戏服务时，我们会收集您的网络身份标识信息及个人常用设备信息，用于标记您为紧张树科技的用户。
                        </div>
                        <div className="content">
                            如果您使用第三方账号作为游戏账号关联登录紧张树科技的，我们会收集您第三方账号的唯一标识、头像、昵称，用于保存您的登录信息，以便您在不同设备登录；
                        </div>
                        <div className="content">
                            如果您使用第三方账号作为游戏账号关联登录紧张树科技游戏的，为了更好地向您提供游戏服务，改善游戏体验，我们会收集您第三方账号涉及的唯一标识、昵称、头像以及您授权的其他信息，以及您在紧张树科技游戏中的相关操作信息、游戏信息等信息（具体包括但不限于您的登录状态、对局信息/状态、成就信息等）进行使用。我们可能会视游戏产品具体情况为您提供相关权限，以便您可以对是否展示前述相关信息进行相应设置。
                        </div>
                        <div className="content">
                            1.2
                            为满足相关法律法规政策及相关主管部门的要求，紧张树科技游戏用户需进行实名认证以继续使用和享受紧张树科技游戏。我们会在获得您同意或您主动提供的情况下收集您的实名身份信息，您可以根据认证要求提供相应的身份信息（身份证、护照、户口本及其他身份证件信息）以完成实名认证以增强账号安全保护。该信息属于敏感信息，拒绝提供实名身份信息可能会导致您无法登陆紧张树科技游戏或在使用紧张树科技游戏过程中受到相应限制。
                        </div>
                        <div className="content">
                            1.3
                            为保障您正常使用我们的服务，维护游戏基础功能的正常运行，优化游戏产品性能，提升您的游戏体验并保障您的账号安全，我们会收集您的设备ID、设备名称、设备类型和版本、系统版本、IP地址和端口号、Android
                            ID、应用ID、网络类型信息用于提供上报服务。
                        </div>
                        <div className="content">
                            1.4
                            为保障您的游戏账号安全，营造公平、健康及安全的游戏环境，我们会收集您的应用列表（软件包名）、进程及游戏崩溃记录的信息，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（用于检测盗版、扫描外挂、防止作弊）。
                        </div>
                        <div className="content">
                            1.5
                            您可以选择开启手机的定位权限，用于提供您的位置信息，以便您接受紧张树科技基于位置提供的相关服务，我们会在您使用相关服务时弹窗提示您是否要开启相应权限。您有权在游戏中关闭位置信息，关闭位置信息后，游戏将不再搜集您的位置信息，但并不影响您对其他游戏功能（需要位置信息的游戏功能除外）的使用。
                        </div>
                        <div className="content">
                            1.6
                            当您使用紧张树科技服务时，我们会收集您的游戏服务日志信息，包括登录日志、物品日志、游戏内行为信息、浏览、点击查看、搜索查询信息，以及IP地址、浏览器类型、运营商、使用语言、访问日期和时间，以便您能够在客户端查看您的游戏历史记录，同时用于游戏运营统计分析、客服投诉处理及其他游戏安全分析，并为提升您的游戏体验，我们可能把前述信息同步至该游戏后续版本或您正在使用的我们提供的其他产品中。我们通过read_logs来读取系统日志，便于在程序出现异常时通过读取系统日志定位和解决问题，确保本程序的正常运行。请您注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
                        </div>
                        <div className="content">
                            1.7
                            如您使用OPPO、VIVO、小米、华为等品牌手机，紧张树科技接入的上述手机厂商SDK需要收集IMEI，并可能会收集您的手机型号、系统类型、系统版本、设备屏幕尺寸、短信参数用于实现紧张树科技产品和活动息的推送或验证，具体情况请参见SDK运营方的隐私政策或相关声明。
                        </div>
                        <div className="content">
                            1.8
                            当您使用紧张树科技产品的消费功能时，我们会收集您的充值记录、消费记录信息，以便您查询您的交易记录，同时尽最大程度保护您的虚拟物品安全。充值记录、消费记录属于敏感信息，收集上述信息为实现紧张树科技产品的消费功能所必须，否则将无法完成交易。
                        </div>
                        <div className="content">
                            1.9
                            为保障您的游戏账号安全，营造公平、健康及安全的游戏环境，我们会收集您的游戏识别信息、硬件及操作系统信息、进程及游戏崩溃记录信息，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如用于检测盗版、扫描外挂、防止作弊等）。
                        </div>
                        <div className="content">
                            1.10
                            当您在游戏中通过文字、图片、语音、视频及其他方式与其他玩家进行互动，我们可能会收集并保存您发送的上述信息内容用于过滤色情、暴力、政治、辱骂、恶意广告等不当内容，以此净化游戏环境，维护健康的上网环境。
                        </div>
                        <div className="content">
                            1.11
                            在您开启摄像头和/或相册权限后，您能够上传、拍摄照片/图片/视频，实现同其他玩家视频或直播互动功能；
                        </div>
                        <div className="content">
                            1.12
                            在您开启麦克风权限后，您可以使用麦克风来进行语音输入，在使用过程中我们需要收集您的语音内容并进行必要的处理；如您不提供前述信息，我们将无法为您提供语音相关服务，但不影响您使用我们提供的其他服务。
                        </div>
                        <div className="content">
                            1.13
                            为了向您提供保存图片、分享图片，客服反馈中上传游戏截图等功能，我们将获取您的存储权限；如果您不提供前述信息，您可能无法使用上述服务，但不影响您使用我们提供的其他服务。
                        </div>
                        <div className="content">
                            为了判断用户与设备的关联关系，并通过技术与风控规则提高登录与交易的安全性，我们需要获取您的电话权限以获取您设备的硬件信息；如果您不提供前述信息，您可能无法使用上述服务，但不影响您使用我们提供的其他服务。
                        </div>
                        <div className="content">
                            为了向您提供在山海界中展示本地天气的功能，我们将获取您的位置权限；如果您不提供前述信息，您可能无法使用上述服务，但不影响您使用我们提供的其他服务。
                        </div>
                        <div className="content">
                            1.14
                            广告服务：为了向您展示广告，我们将收集和处理您的以下信息，并仅在下述的目的范围内分享给第三方广告服务平台：
                        </div>
                        <div className="content">
                            {' '}
                            1）设备及使用信息：IMEI、操作系统的设置信息、设备的硬件信息、应用的基本信息及使用信息、网络信息、运营商信息、渠道帐号信息。
                        </div>
                        <div className="content">
                            {' '}
                            2）广告互动信息：对广告的浏览、点击、关闭和播放信息。打开和关闭应用的时间、应用使用频率、应用错误日志。
                        </div>
                        <div className="content">
                            {' '}
                            3）位置信息，我们会收集、使用并处理您设备的模糊位置或准确位置，这些位置信息通过
                            GPS、WLAN 和服务提供商的网络 ID
                            获取。我们会询问您要为我们应用程序启用基于位置的服务。您可在设备的设置菜单中选择关闭设备上的相应权限，拒绝共享您的位置信息。
                        </div>
                        <div className="content">
                            上述数据将会传输并保存至【中华人民共和国国境内】的服务器，保存期限为2年，超出这一保留时间后会删除，但法律法规另有要求除外。如因法律法规明确规定，或者得到您的明确授权，我们确需向境外机构传输境内收集的相关个人信息的，我们也会根据国内法律、行政法规和相关监管部门的规定，为您的个人信息提供保护。
                        </div>
                        <div className="content">
                            1.15
                            当您使用紧张树科技服务时，为了更好地向您提供游戏服务，改善游戏体验、保障您正常使用我们的服务，维护游戏基础功能的正常运行，优化游戏产品性能并保障您的账号安全，我们会收集和使用您的IMEI、和网络状态。
                        </div>
                        <div className="content">
                            1.16
                            您可通过我们为您提供的评论、问答、论坛及其他信息发布功能公开发表言论信息。
                        </div>
                        <div className="content">
                            请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时公开分享相关个人信息。若您公开发布的信息中涉及儿童个人信息的，您需在发布前征得对应儿童监护人的同意。
                        </div>
                        <div className="content">
                            1.17
                            若您提供的信息中含有其他用户的个人信息，在向本平台提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权联系我们，要求更正或删除涉及儿童个人信息的内容。
                        </div>
                        <div className="content">
                            1.18
                            根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：
                        </div>
                        <div className="content">
                            {' '}
                            1）与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；
                        </div>
                        <div className="content">
                            {' '}
                            2）与犯罪侦查、起诉、审判和判决执行等直接相关的；
                        </div>
                        <div className="content">
                            {' '}
                            3）出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；
                        </div>
                        <div className="content">
                            {' '}
                            4）所收集的个人信息是您自行向社会公众公开的；
                        </div>
                        <div className="content">
                            {' '}
                            5）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                        </div>
                        <div className="content">
                            {' '}
                            6）根据您要求签订和履行合同所必需的；
                        </div>
                        <div className="content">
                            {' '}
                            7）用于维护所提供的产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
                        </div>
                        <div className="content">
                            {' '}
                            8）为开展合法的新闻报道所必需的；
                        </div>
                        <div className="content">
                            {' '}
                            9）出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
                        </div>
                        <div className="content">
                            {' '}
                            10）法律法规规定的其他情形。
                        </div>
                        <div className="content">
                            1.19
                            请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。
                        </div>
                        <div className="content">
                            1.20
                            请您理解，为共同向您提供产品服务或改进产品服务的质量或出于对产品服务安全性考量的合理需要，我们可能按照相关法律法规及监管政策的要求或经过您的授权从关联公司、合作伙伴及其他受信任的第三方接收您的个人信息及其他信息，若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任。
                        </div>
                        <div className="content">
                            1.21
                            请您理解，在从第三方接收您的个人信息前，我们无权决定且无法知悉第三方获取您个人信息的范围，如第三方开展业务所进行的个人信息处理活动超出您向我们提供个人信息时的授权同意范围、或第三方违法违规收集您的个人信息（如渠道违反必要原则，收集您的通讯录信息），责任应由第三方承担，请您立即和我们取得联系，以便我们及时通知第三方采取相应措施以避免或降低相关损失。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>二、我们如何使用COOKIES或同类技术</strong>
                        </div>
                        <div className="content">
                            我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的信息，并将该等信息储存为日志信息。
                        </div>
                        <div className="content">
                            通过使用COOKIES，我们向用户提供简单易行的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得紧张树科技的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录紧张树科技的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在紧张树科技特定服务上花费的时间和您所选择的紧张树科技服务。
                        </div>
                        <div className="content">
                            COOKIES使得我们能更好、更快地为您服务，并且使您在紧张树科技服务上的经历更富。然而，您应该能够控制COOKIES是否以及怎样被您的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。
                        </div>
                        <div className="content">
                            我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的信息，并将该等信息储存。
                        </div>
                        <div className="content">
                            我们使用自己的COOKIES或同类技术，可能用于以下用途：
                        </div>
                        <div className="content">
                            （1）记住您的身份。如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；
                        </div>
                        <div className="content">
                            （2）分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用紧张树科技服务进行什么活动、或哪些服务最受欢迎；
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>
                                三、我们可能分享、转让或披露的个人信息
                            </strong>
                        </div>
                        <div className="content">（一）分享</div>
                        <div className="content">
                            除以下情形外，未经您同意，我们不会与紧张树科技之外的任何第三方分享您的个人信息：
                        </div>
                        <div className="content">
                            向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现我们产品与/或服务的功能，让您正常使用您需要的服务：提供支付服务的支付机构、提供数据服务（包括网络广告监测、数据统计、数据分析）的合作伙伴、第三方物流公司和其他服务提供商，我们可能通过在游戏中内置合作伙伴提供的SDK的方式将您的个人信息进行分享，您可以通过点击《第三方信息共享清单》查阅信息。
                        </div>
                        <div className="content">
                            （1）高德定位sdk：用于提供基于地理位置的服务；山海界的天气会随着本地的天气而变化，所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、您的精良位置(GPS)或粗略位置、您的外部存储信息、IMEI、IMSI、Android
                            id、传感器、运营商信息、移动设备识别码、设备序列号、MAC；
                        </div>
                        <div className="content">
                            （2）极光Push
                            sdk：用于推送游戏内消息、通知；所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、外部存储信息、系统设置信息、sd卡读写文件信息；
                        </div>
                        <div className="content">
                            （3）今日头条数据统计sdk：用于给游戏提供事件上报统计用以分析游戏运营数据服务；所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、您的外部存储信息、手机厂商名、产品名、手机品牌、手机型号、设备宽、高、IMEI信息、手机硬件名、SDK版本、android版本；
                        </div>
                        <div className="content">
                            （4）穿山甲广告sdk：用于展示穿山甲平台提供广告信息；所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、您的外部存储信息、您的精良位置(GPS)或粗略位置、您手机当前或最近运行的任务信息；
                        </div>
                        <div className="content">
                            （5）广点通数据统计sdk：用于给游戏提供事件上报统计用以分析游戏运营数据服务；所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、手机厂商名、产品名、手机品牌、手机型号、设备宽、高、IMEI信息、手机硬件名、SDK版本、android版本；
                        </div>
                        <div className="content">
                            （6）华为HMS：用于用户体验华为平台提供的华为云服务的合集，所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、IMEI、正在运行的应用列表、运营商信息、Android
                            id、通话状态、您的外部存储信息、您的设备读取权限、安装其他应用权限、您手机当前的服务地信息；
                        </div>
                        <div className="content">
                            （7）华为推送SDK：通过识别设备信息为App赋予推送能力，用于消息推送；所分享的信息可能包含AAID（应用匿名标识）、Token、Topic订阅关系、消息下发记录、Token申请记录、缓存消息内容；
                        </div>
                        <div className="content">
                            （8）腾讯开放平台SDK：用于微信账号登录服务;所分享的信息可能包含设备标识信息（MAC/AndroidID/硬件序列号）、IP地址、存储权限；
                        </div>
                        <div className="content">
                            （9）QQ登录com.tencent.open：用于使用qq进行第三方账号登录，所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、外部存储信息、系统设置信息、读取日志信息；
                        </div>
                        <div className="content">
                            （10）第三方物流公司：您在紧张树科技科技服务使用过程中，可能获得合法实物奖励，出于向您按时发放奖品的需要，我们会向合法的物流服务提供商共享您所提供的电话、收件人姓名及地址信息；
                        </div>
                        <div className="content">
                            （11）剪贴板：用于文字内容的复制粘贴，此功能由第三方提供，便于您录入、编辑文档内容，被读写的信息可能有您输入、编辑的文档信息；
                        </div>
                        <div className="content">
                            （12）微信分享sdk：用于向您提供微信分享功能；所分享的信息可能有您的手机SD卡中的信息及外部存储卡中的信息；
                        </div>
                        <div className="content">
                            （13）腾讯Bugly
                            sdk：用于给游戏提供崩溃监控和上报服务；所分享的信息可能有您的网络状态、手机SD卡中的信息及外部存储卡中的信息、您手机的一些系统信息：IMEI、序列号、常用的系统版本号、手机型号、硬件识别码、序列号、是否在充电、是否在通话用于提供上报服务；
                        </div>
                        <div className="content">
                            （14）移动终端补充设备标识体系统一调用sdk：用于给游戏提供事件上报统计用以分析游戏运营数据服务；所分享的信息可能有您的oaid信息；
                        </div>
                        <div className="content">
                            （15）移动安全联盟SDK：移动智能终端补充设备标识体系统一调用的SDK,用于游戏获取手机的补充设备标识，作为您android的设备唯一标识；
                        </div>
                        <div className="content">
                            （16）小米Push
                            sdk：用于推送游戏内消息、通知等；所分享的信息可能有您的网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、外部存储信息、系统设置信息；
                        </div>
                        <div className="content">
                            （17）微博分享SDK：用于向您提供微博分享功能、微博登录功能；所分享的信息可能有您的手机SD卡中的信息、IMEI、IMSI、Android
                            id、ICCID、设备序列号、MAC、外部存储卡中的信息；
                        </div>
                        <div className="content">
                            （18）QQ分享sdk：用于向您提供QQ分享功能；所分享的信息可能有您的手机SD卡中的信息、外部存储卡中的信息；
                        </div>
                        <div className="content">
                            （19）号码认证服务sdk：在经过用户授权后，从网关侧获取用户当前使用的手机号码，并完成注册或登录流程。会获取手机号码、设备信息（IP地址、设备制造商、设备型号、手机操作系统、SIM卡信息SIM
                            State、SIM卡信息ICCID）、网络状态；
                        </div>
                        <div className="content">
                            （20）支付宝：游戏内道具资源购买时唤起支付宝app进行支付；所分享的信息可能有您的网络状态、设备信息（MAC/Android
                            ID/IMEI/IMSI/SIM卡序列号/硬件序列号/SSID/BSSID、IP地址）、联网方式、手机号码、支付宝账号；
                        </div>
                        <div className="content">
                            （21）数数科技：给游戏提供事件上报统计用以分析游戏运营数据服务，网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态;
                        </div>
                        <div className="content">
                            （22）手游智能反外挂SDK：会通过SDK收集信息，并通过加密的方式传送到易盾移动安全服务器，进行外挂智能挖掘，收集信息包括操作系统版本、运营商、序列号等基础信息、设备信息（设备唯一识别码（设备序列号、IMEI、MAC地址、AndroidID、IDFV、IDFA）、设备品牌、设备名称、设备型号及设备系统类型、详细设置及版本信息）、环境信息如网络链接类型，USB调试模式、用户在终端屏幕中点击的行为模式；
                        </div>
                        <div className="content">
                            （23）vivo推送SDK：用于推送游戏内消息、通知；所分享的信息可能有你的设备信息（设备标识信息（MAC/Android
                            ID/硬件序列号）、IP地址）、应用信息、网络信息；
                        </div>
                        <div className="content">
                            （24）oppo OPUSH
                            SDK：用于推送游戏内消息、通知；所分享的信息可能有你的设备信息（设备标识信息（MAC/Android
                            ID/硬件序列号）、IP地址）、应用信息、网络信息；
                        </div>
                        <div className="content">
                            （25）华为推送SDK：通过识别设备信息为APP赋予推送能力，用于消息推送，以及统计华为推送SDK接口调用成功率；所分享的信息可能包含应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息；
                        </div>
                        <div className="content">
                            （26）华为分析SDK（Android版）：用于提供数据采集、数据管理、数据分析；所分享的信息可能有你的应用信息、设备信息（应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息）、帐号信息、网络信息、应用使用信息；
                        </div>
                        <div className="content">
                            （27）Unity广告服务：用于展示unity平台提供广告信息；所分享的信息可能有您的设备信息（Android
                            id、IMEI）、网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、通话状态、运营商信息、您的外部存储信息、您的精良位置(GPS)或粗略位置、您手机当前或最近运行的任务信息；
                        </div>
                        <div className="content">
                            （28）乐变分包SDK：给游戏提供更新功能，所分享的信息有您网络信息状态、当前WiFi接入的状态以及WLAN热点的信息、设备信息（会收集手机品牌、型号、系统版本、网络类型、内存大小、屏幕分辨率等用作统计目的，所有信息均已做匿名化处理）、正在运行的应用列表、设备存储空间信息、应用程序列表信息、正在运行的进程；
                        </div>
                        <div className="content">
                            2、实现本政策第一条“我们如何收集和使用个人信息”部分所述目的；
                        </div>
                        <div className="content">
                            3、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；
                        </div>
                        <div className="content">
                            4、向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；
                        </div>
                        <div className="content">
                            5、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他紧张树科技用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；
                        </div>
                        <div className="content">
                            6、应您合法需求或经您的授权同意；
                        </div>
                        <div className="content">
                            7、应您的监护人合法要求而提供您的信息；
                        </div>
                        <div className="content">
                            8、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
                        </div>
                        <div className="content">9、基于学术研究而提供；</div>
                        <div className="content">
                            10、基于符合法律法规的社会公共利益而提供。
                        </div>
                        <div className="content">
                            我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。
                        </div>
                        <div className="content">（二）转让</div>
                        <div className="content">
                            1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
                        </div>
                        <div className="content">
                            2、在获得您的明确同意后，我们会向其他方转让您的个人信息。
                        </div>
                        <div className="content">（三）披露</div>
                        <div className="content">
                            我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：
                        </div>
                        <div className="content">
                            1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
                        </div>
                        <div className="content">
                            2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
                        </div>
                        <div className="content">
                            （四）分享、转让、披露信息时事先征得授权同意的例外
                        </div>
                        <div className="content">
                            以下情形中，分享、转让、披露您的信息无需事先征得您的授权同意：
                        </div>
                        <div className="content">
                            1、与国家安全、国防安全有关的；
                        </div>
                        <div className="content">
                            2、与公共安全、公共卫生、重大公共利益有关的；
                        </div>
                        <div className="content">
                            3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
                        </div>
                        <div className="content">
                            4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
                        </div>
                        <div className="content">
                            5、您自行向社会公众公开的信息；
                        </div>
                        <div className="content">
                            6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道。
                        </div>
                        <div className="content">
                            根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>四、我们如何储存和保护个人信息</strong>
                        </div>
                        <div className="content">
                            我们仅在本政策所述目的所必需期间和法律法规要求的最短时限内储存您的个人信息。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
                        </div>
                        <div className="content">
                            我们在中华人民共和国境内运营中收集和产生的个人信息，储存在中国境内，以下情形除外：
                        </div>
                        <div className="content">1、法律法规有明确规定；</div>
                        <div className="content">2、获得您的授权同意；</div>
                        <div className="content">
                            3、您使用的产品、服务涉及跨境，紧张树科技需要向境外提供您的个人信息的。
                        </div>
                        <div className="content">
                            针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。
                        </div>
                        <div className="content">
                            我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：
                        </div>
                        <div className="content">（一）数据安全技术措施</div>
                        <div className="content">
                            我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
                        </div>
                        <div className="content">
                            我们的服务采取了多种加密技术，如在某些产品中，我们将利用加密技术（如MD5）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。
                        </div>
                        <div className="content">
                            在个人信息使用时，如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。
                        </div>
                        <div className="content">
                            我们还会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
                        </div>
                        <div className="content">
                            （二）我们为保护个人信息采取的其他安全措施
                        </div>
                        <div className="content">
                            我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
                        </div>
                        <div className="content">
                            我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。我们还会举办安全和隐私保护培训课程，加强员工的安全意识以及对于保护信息重要性的认识。
                        </div>
                        <div className="content">
                            （三）我们仅允许有必要知晓这些信息的紧张树科技员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与紧张树科技的合作关系。
                        </div>
                        <div className="content">
                            （四）我们会采取一切合理可行的措施，确保未收集无关的个人信息。
                        </div>
                        <div className="content">
                            （五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。
                        </div>
                        <div className="content">（六）安全事件处置</div>
                        <div className="content">
                            在通过紧张树科技服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
                        </div>
                        <div className="content">
                            为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
                        </div>
                        <div className="content">
                            在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
                        </div>
                        <div className="content">
                            请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入紧张树科技服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
                        </div>
                        <div className="content">
                            请您务必妥善保管好您的账号、密码及其他身份要素。您在使用紧张树科技服务时，我们会通过您的账号、密码及及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现账号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>五、如何管理您的个人信息</strong>
                        </div>
                        <div className="content">（一）访问、更新和删除</div>
                        <div className="content">
                            我们鼓励您更新和修改您的个人信息以使其更准确有效。您能通过紧张树科技服务访问您的个人信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。
                        </div>
                        <div className="content">
                            如果您希望访问或编辑您的账户中的个人资料信息，您可以通过访问您的账户个人资料页执行此类操作。如您想删除您的个人信息时（包括邮箱、城市、地址、性别、生日、实名认证信息），您可以通过使用联系游戏内在线客服或致电客服电话进行更正。
                        </div>
                        <div className="content">
                            在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。对于通过COOKIES或同类技术收集的您的信息，我们还在本政策第二条“我们如何使用COOKIES或同类技术”部分说明了向您提供的选择机制。如您想查询部分个人信息或获取个人信息副本，可在游戏主界面左下角“设置→隐私设置→个人信息查询”界面查询及获取个人信息副本。如您想修改或删除您的部分信息，请联系紧张树科技客服具体指引进行操作，我们将在3个工作日内为您处理。
                        </div>
                        <div className="content">
                            除法律法规另有规定，当您更正、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。
                        </div>
                        <div className="content">（二）公开与分享</div>
                        <div className="content">
                            我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，如，您在紧张树科技服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在紧张树科技服务中不特定用户可见的公开区域内上传或公布您的信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信
                            息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。
                        </div>
                        <div className="content">（三）注销</div>
                        <div className="content">
                            如果您想注销您的账户，您可以通过“客服--账号相关--注销账号”功能按照相应的要求提交帐号注销申请，在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息（包括与您账号相关的、该单项服务项下的全部服务资料和数据），法律法规另有规定的除外。
                        </div>
                        <div className="content">（四）撤回您的授权</div>
                        <div className="content">
                            您可以进入游戏内主界面“设置→政策信息→隐私协议→撤回授权”点击“确定撤回”按钮来撤回您的授权。当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。
                        </div>
                        <div className="content">
                            您可以通过“更多→设置→隐私设置”中查看您的设备功能在App的授权列表，如您希望对设备权限做出变动，可点击权限后按钮前往手机权限设置界面进行修改。您也可以在手机系统设置中权限管理界面手动修改。您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>六、第三方服务</strong>
                        </div>
                        <div className="content">
                            紧张树科技服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
                        </div>
                        <div className="content">
                            （1）您可利用“分享”将某些内容分享到第三方服务，或您可利用第三方服务登录紧张树科技服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；
                        </div>
                        <div className="content">
                            （2）我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；
                        </div>
                        <div className="content">
                            （3）其他接入第三方服务的情形。如，为实现本政策中声明的目的，我们可能会接入第三方SDK服务，并将我们依照本政策收集的您的某些个人信息共享给该等第三方，以便提供更好的客户服务和用户体验。
                        </div>
                        <div className="content">
                            该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>七、年龄限制</strong>
                        </div>
                        <div className="content">
                            我们建议：任何未成年人参加网上活动应事先取得父母或其他监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。
                        </div>
                        <div className="content">
                            我们鼓励监护人指导未成年人使用紧张树科技服务。如果您是未满十四周岁的未成年人，您需要通知您的监护人共同阅读并接受我们的《紧张树科技儿童隐私保护指引》
                            ，并在您使用紧张树科技服务、提交个人信息之前，寻求其同意和指导。
                        </div>
                        <div className="content">
                            我们只会在获得（推定获得）法定监护人同意的前提下，收集、使用未成年人的个人信息，且根据相关法律法规和监管政策的规定，使用和处理获得的未成年人个人信息。如发现在未事先获得法定监护人同意的情况下，我们可能收集了未成年人的个人信息，我们会对该等数据和个人信息尽快进行删除等操作处理。
                        </div>
                        <div className="content">
                            若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请及时与我们的客服联系。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>八、通知和修订</strong>
                        </div>
                        <div className="content">
                            为了给您提供更好的服务，我们可能会根据产品或服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知您，您如果不同意该等变更，可以选择停止使用紧张树科技服务；只有在您点击弹窗中的同意按钮后，我们才会按照变更后的隐私权政策收集、使用、共享、保存您的个人信息。
                        </div>
                        <div className="content">
                            我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用紧张树科技服务时都查阅我们的隐私政策。
                        </div>
                        <div className="content">
                            使用紧张树科技游戏服务可能需要下载并安装相关软件，您可以直接从紧张树科技的相关网站上获取该软件，也可以从得到紧张树科技授权的第三方获取。如果您从未经紧张树科技授权的第三方获取该软件或与该软件名称相同的软件，将视为您未获得紧张树科技授权，紧张树科技无法保证该软件能够正常使用、无法保证您及时获得有关紧张树科技政策的修订通知，并对因此给您造成的损失不予负责。
                        </div>
                        <div className="content">
                            我们可能在必需时（如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>九、如何联系我们</strong>
                        </div>
                        <div className="content">
                            如您有关于网络信息安全的投诉和举报，或您对本政策、紧张树科技的隐私措施、您的信息的相关事宜有任何问题、意见或建议，请随时与我们联系，可电子邮件联系合作联系：fengdanyi@nervoustree.com
                            一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五天内予以回复。
                        </div>
                        <div className="right-content">
                            杭州紧张树科技有限公司
                        </div>
                        <div className="blank" />
                        <div className="blank" />
                        <div className="blank" />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Privacy;
