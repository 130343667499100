import React from 'react';
import { Row, Col } from 'antd';

const Children = () => {
    return (
        <div>
            <Row>
                <Col lg={{ span: 18, offset: 3 }}>
                    C
                    <div style={{ paddingTop: '10vh' }}>
                        <div className="policy-title">儿童隐私协议</div>
                        <div className="content">
                            欢迎您选择由杭州紧张树科技有限公司（以下简称“我们”或“紧张树科技”）提供的游戏！除《紧张树科技隐私政策》、《紧张树科技用户协议》外，我们还将通过《紧张树科技儿童隐私保护指引》（“本政策”）帮助您和孩子（本政策中的“孩子”，是指不满十四周岁的未成年人）进一步了解我们收集、使用、存储和共享您孩子个人信息的情况，以及您和您的孩子所享有的相关权利。
                        </div>
                        <div className="content">
                            本协议的订立、履行、解释及争议的解决均适用中华人民共和国法律并排除其他一切冲突法的适用。如双方就本协议的内容或其执行发生任何争议（包括但不限于合同或者其他财产性权益纠纷），双方应友好协商解决；协商不成时，双方同意交由杭州紧张树科技有限公司所在地人民法院管辖并处理。
                        </div>
                        <div className="content">
                            儿童特别说明：本政策内的儿童指不满十四周岁的未成年人用户。如果您是儿童，请通知并确保您的监护人务必认真阅读并充分理解本儿童隐私政策，并在您使用紧张树科技服务、提交个人信息之前，寻求、获得您的监护人的同意和指导。您点击同意本儿童隐私政策，或者您使用/继续使用紧张树科技服务、提交个人信息，我们将善意的理解前述行为表示您已获得您的监护人的许可，您的监护人同意按照本儿童隐私政策（包括更新版本）同意/授权我们收集、存储、使用、转移和披露儿童的个人信息。
                        </div>
                        <div className="content">
                            监护人特别说明：如您的孩子不满十四周岁将适用本儿童隐私政策，请您作为儿童的法定监护人应认真阅读并理解我们的儿童隐私保护政策。我们将根据本儿童隐私政策采取特殊措施保护您孩子的个人信息。请您帮助我们，履行保护儿童个人信息和隐私安全的责任，要求他们在您的监护下共同阅读并接受本隐私政策，且应在您的同意和指导下使用紧张树科技服务、提交个人信息。如果您不同意本隐私政策的内容，将可能导致紧张树科技的产品和服务无法正常运行，或者无法达到紧张树科技拟达到的服务效果，且您应要求您的孩子立即停止访问/使用紧张树科技服务。您点击同意本隐私政策，或者您的孩子使用/继续使用紧张树科技服务、提交个人信息，我们都理解并确信该等行为表示您同意紧张树科技按照本隐私政策（包括更新版本）收集、存储、使用、转移和披露您孩子的个人信息。
                        </div>
                        <div className="content">
                            <strong>【重要提示】：</strong>
                        </div>
                        <div className="content">
                            本政策适用于不满十四周岁的未成年人的个人信息处理。有关您和已满十四周岁的用户的个人信息处理，请查看《紧张树科技隐私政策》了解相关信息。
                        </div>
                        <div className="content">
                            我们会通过页面提示、交互流程、网站公告等方式向您说明儿童个人信息收集等情况，并征得您的同意。请您仔细阅读、充分理解《紧张树科技隐私政策》和本政策后，选择是否同意前述隐私政策，特别是以黑色加粗标识的条款。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>一、我们收集的儿童个人信息</strong>
                        </div>
                        <div className="content">
                            在您的孩子使用紧张树科技服务的过程中，紧张树科技仅会收集您同意我们收集的或您及您的孩子主动提供的有关您孩子的个人信息，以向您的孩子提供、优化我们的服务以及保障您孩子的账户安全。同时紧张树科技将为您提供拒绝选项，如您未同意我们紧张树科技收集信息的请求，视为您同意紧张树科技将无法继续为您以及您的孩子提供相应的服务。我们可能会收集的您孩子的个人信息的详情，请参见《紧张树科技隐私政策》。
                        </div>
                        <div className="content">
                            同时，为验证您与您孩子的监护关系，我们可能还会收集您的联系方式（包括手机号码、电子邮箱）以及其他有助于我们判断监护关系的信息。
                        </div>
                        <div className="content">
                            目前，除了向第三方调研机构收集游戏调研信息，以帮助我们改进游戏产品，我们不会主动从紧张树科技外的第三方获取您孩子的个人信息。如未来为业务发展需要从紧张树科技外的第三方间接获取您孩子的个人信息，我们会在获取前向您明示个人信息的来源、类型及使用范围，如紧张树科技开展业务需进行的个人信息处理活动超出您原本向紧张树科技外的第三方提供个人信息时的授权同意范围，我们将在处理您孩子的该等个人信息前，征得您的明示同意；此外，我们也将会严格遵守相关法律法规的规定，并要求该第三方保障其提供的信息的合法性。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>二、我们如何使用儿童个人信息</strong>
                        </div>
                        <div className="content">
                            我们严格遵守法律法规的规定以及与用户的约定，按照本政策及《紧张树科技用户协议》、《紧张树科技隐私政策》所述使用收集的信息，以向您的孩子提供更为优质的服务。
                        </div>
                        <div className="content">
                            有关我们使用儿童个人信息的方式详情请参见《紧张树科技隐私政策》，若我们使用您孩子的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您孩子的个人信息前，再次向您告知并征得您的同意。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>三、儿童个人信息的存储</strong>
                        </div>
                        <div className="content">
                            3.1我们按照法律法规规定，将在中华人民共和国境内收集到的您孩子的个人信息存储于中华人民共和国境内，并依法对这些信息进行严格保密。如涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们也会根据国内法律、行政法规和相关监管部门的规定，为您孩子的个人信息提供保护。
                        </div>
                        <div className="content">
                            3.2一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您孩子的个人信息。超出法律法规或监管规定的期限后，我们会按照法律法规的要求对您孩子的个人信息进行删除或者匿名化处理。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>四、儿童个人信息的安全</strong>
                        </div>
                        <div className="content">
                            4.1为了保障您孩子的信息安全，我们会在现有技术水平下采取合理必要的措施来保护孩子的信息，采取物理防护、安全技术、管理制度等措施来降低丢失、误用、非授权访问、披露和更改的风险，包括但不限于数据加密传输、防火墙和加密存储、物理访问控制以及信息访问授权控制。为此我们设置了安全程序保护您孩子的信息不会被未经授权的访问所窃取，所有的个人信息被加密储存并放置于经防火墙严格保护的内部系统。
                        </div>
                        <div className="content">
                            4.2为了保障您孩子的信息安全，我们建立了专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。
                        </div>
                        <div className="content">
                            4.3若发生儿童个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并及时以公告、推送通知或邮件等形式告知您和孩子安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>五、儿童个人信息的更正</strong>
                        </div>
                        <div className="content">
                            如您和孩子发现儿童个人信息有错误的，可以联系我们处理。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>六、儿童个人信息的删除</strong>
                        </div>
                        <div className="content">
                            6.1如您和孩子发现我们违反法律、行政法规的规定或者双方的约定处理儿童个人信息的，或是超出目的范围或者必要期限处理儿童个人信息的，可以通过联系我们对相关个人信息进行删除。
                        </div>
                        <div className="content">
                            6.2如您撤回同意的，可以联系我们，我们将按照国家有关法律规定进行处理。
                        </div>
                        <div className="content">
                            6.3如您和孩子主动注销紧张树科技账号并终止使用紧张树科技，我们将会停止使用儿童的个人信息，但法律法规或监管部门另有规定的除外。如我们的产品或者服务停止运营，我们将根据相关法律法规规定进行通知，同时也将及时停止使用儿童个人信息，并将对保存的儿童个人信息进行删除或匿名化处理。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>七、儿童个人信息的转移和委托处理</strong>
                        </div>
                        <div className="content">
                            我们不会向第三方转移和委托第三方处理儿童个人信息，除以下情况外，未经监护人的另外授权同意，我们不会向第三方转移、委托第三方处理或者与第三方分享儿童个人信息：
                        </div>
                        <div className="content">
                            7.1为实现本政策第2条“我们如何使用儿童个人信息”中所述目的，或者为履行本政策或我们与儿童、监护人达成的其他协议中约定的我们的义务以及行使我们的权利；
                        </div>
                        <div className="content">
                            7.2在遵循法律法规要求的前提下，为了符合法律规定、维护儿童、监护人、紧张树科技、紧张树科技的关联方、紧张树科技的合作伙伴或其他紧张树科技用户的合法权益或社会公众利益的需要（比如为防止违法活动），我们可能与其他组织交换信息。
                        </div>
                        <div className="content">
                            对于上述确需第三方转移或委托第三方处理的，我们都会根据法律、行政法规的规定进行合规措施，包括但不限于对第三方进行安全评估。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>八、儿童个人信息的披露</strong>
                        </div>
                        <div className="content">
                            除非法律、行政法规规定应当披露或者根据与您的约定可以披露您孩子的个人信息的，我们不会披露孩子的个人信息。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>九、变更</strong>
                        </div>
                        <div className="content">
                            我们可能会适时对本政策进行修订。当本政策的条款发生变更时，我们会在版本更新时以适当的方式向您提示变更后的指引。请您仔细阅读变更后的隐私保护指引或指引内容，您的孩子继续使用紧张树科技表示您同意我们按照更新后的隐私保护指引收集、处理或使用您孩子的个人信息。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>十、其他</strong>
                        </div>
                        <div className="content">
                            《紧张树科技隐私政策》是紧张树科技统一适用的一般性隐私条款，其中所规定的内容包括但不限于用户权利及信息安全保障措施等均适用于紧张树科技用户。本政策是专门针对儿童的隐私保护指引，包含对于儿童个人信息的特殊保护，属于《紧张树科技隐私政策》的补充内容。如《紧张树科技隐私政策》与本政策存在不一致或矛盾之处，请以本政策为准。
                        </div>
                        <div className="blank" />
                        <div className="content">
                            <strong>十一、联系我们</strong>
                        </div>
                        <div className="content">
                            我们设有个人信息保护专职部门并指定了专门的儿童个人信息保护负责人，将严格按照本政策保护儿童个人信息。如监护人和儿童有关于网络信息安全的投诉和举报，或对本政策、紧张树科技的儿童个人信息保护规则、措施的相关事宜有任何问题、意见或建议，请随时与我们联系，可电子邮件联系：fengdanyi@nervoustree.com
                        </div>
                        <div className="content">
                            一般情况下，我们将在收到问题、意见或建议，并验证监护人和/或儿童身份后的十五天内予以回复。
                        </div>
                        <div className="right-content">
                            杭州紧张树科技有限公司
                        </div>
                        <div className="blank" />
                        <div className="blank" />
                        <div className="blank" />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Children;
