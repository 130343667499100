import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import User from './user';
import Privacy from './privacy';
import Children from './children';
import '@styles/content.css';

const componentMap = {
    user: <User key="user" />,
    privacy: <Privacy key="privacy" />,
    children: <Children key="children" />,
};

const tags = [
    { id: 'user', label: '用户协议' },
    { id: 'privacy', label: '隐私政策' },
    { id: 'children', label: '儿童隐私政策' },
];

const Policy = () => {
    const location = useLocation();
    const history = useHistory();
    const [path, setPath] = useState('user');

    useEffect(() => {
        let splits = location.pathname.split('/');
        if (splits.length < 3 || componentMap[splits[2]] === undefined) {
            history.push('/policy/user');
        } else {
            setPath(splits[2]);
        }
    }, [location]);

    return (
        <div>
            <div className="header-logo" onClick={() => history.push('/')} />
            <div className="header">
                <Row align="middle">
                    <Col lg={{ span: 3 }}></Col>
                    {tags.map((i) => (
                        <Col
                            className={i.id === path ? 'selected-label' : ''}
                            lg={2}
                            style={{ cursor: 'pointer', textAlign: 'center' }}
                            onClick={() => history.push('/policy/' + i.id)}
                        >
                            {i.label}
                            <div
                                style={{
                                    height: '20px',
                                    padding: '17px 20px 0 20px',
                                }}
                            >
                                <div
                                    className={
                                        i.id === path ? 'selected-tab' : ''
                                    }
                                    style={{
                                        height: '3px',
                                    }}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div style={{ paddingTop: '20px' }}>{componentMap[path]}</div>
        </div>
    );
};

export default Policy;
